import React, { useContext } from "react";
import { GenericBanner, useDeviceTypes } from "halifax";
import { RouterProps } from "react-router-dom";
import { B2B_PORTAL_BANNER_CONTAINER_ID } from "redmond";
import { ClientContext } from "../../../../App";

import { RewardsBannerConnectorProps } from "./container";
import { PATH_VACATION_RENTALS_SHOP } from "../../../../utils/paths";
import { VACATION_RENTALS_EARN_BANNER_TEXT } from "./constants";

export interface IRewardsBannerProps
  extends RewardsBannerConnectorProps,
    RouterProps {}

export const RewardsBanner = (props: IRewardsBannerProps) => {
  const { history, largestValueAccount: account } = props;
  const firstName =
    useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  const { matchesMobile } = useDeviceTypes();

  let contentText = "";

  const {
    earn,
    lastFour = "",
    productDisplayName: productId = "",
    rewardsBalance,
  } = account || {};
  const { currency, currencyDescription } = rewardsBalance || {};
  const { hotelsMultiplier: hotelsEarnRate = "" } = earn || {};

  //if no earn multiplier do not display a banner or if its less than 0
  if (!account || !account?.earn) {
    return null;
  }

  switch (history.location.pathname) {
    case PATH_VACATION_RENTALS_SHOP.slice(0, -1):
    case PATH_VACATION_RENTALS_SHOP:
      contentText = VACATION_RENTALS_EARN_BANNER_TEXT({
        firstName,
        hotelsEarnRate,
        homesEarnRate: hotelsEarnRate,
        productId,
        lastFour,
        currency: currencyDescription || currency,
      });
      break;
    default:
      contentText = "";
  }

  if (!contentText) {
    return null;
  }

  return (
    <GenericBanner
      className="rewards-banner-root"
      content={contentText}
      containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
      isMobile={matchesMobile}
    />
  );
};
