import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../reducers/types";
import { fetchVacationRentalShop } from "./actions/actions";
import {
  getVacationRentalShopCallState,
  getVacationRentalShopSelectedListing,
  getViewedVacationRentalDetailsProperties,
  VacationRentalShopCallState,
} from "./reducer";
import { withRouter } from "react-router";
import { VacationRentalShop } from "./component";
import { getRewardsAccounts } from "../rewards/reducer";
import { actions as rewardsActions } from "../rewards/actions";

const mapStateToProps = (state: IStoreState) => {
  const callState = getVacationRentalShopCallState(state);
  return {
    selectedListing: getVacationRentalShopSelectedListing(state),
    rewardsAccounts: getRewardsAccounts(state),
    fetchedVacationRentalDetails:
      callState === VacationRentalShopCallState.Failed ||
      callState === VacationRentalShopCallState.Success,
    viewedVacationRentalDetailsProperties:
      getViewedVacationRentalDetailsProperties(state),
  };
};

const mapDispatchToProps = {
  fetchVacationRentalShop,
  setSelectedAccount: rewardsActions.setSelectedAccountReferenceId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VacationRentalShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedVacationRentalShop = withRouter(
  connector(VacationRentalShop)
);
