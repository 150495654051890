import { IStoreState } from "../../../../reducers/types";
import { createSelector } from "reselect";
import dayjs from "dayjs";
import { IResult } from "redmond";

export const getLocation = (state: IStoreState): IResult | null =>
  state.vacationRentalSearch.location;

export const getFromDate = (state: IStoreState) =>
  state.vacationRentalSearch.fromDate;

export const getUntilDate = (state: IStoreState) =>
  state.vacationRentalSearch.untilDate;

export const getAdultsCount = (state: IStoreState) =>
  state.vacationRentalSearch.adultsCount;

export const getChildrenCount = (state: IStoreState) =>
  state.vacationRentalSearch.children.length;

export const getPetsCount = (state: IStoreState) =>
  state.vacationRentalSearch.petsCount;

export const getChildren = (state: IStoreState) =>
  state.vacationRentalSearch.children;

export const getNightCount = createSelector(
  getFromDate,
  getUntilDate,
  (fromDate, untilDate): number | null => {
    if (fromDate && untilDate) {
      return dayjs(untilDate).diff(dayjs(fromDate), "day");
    } else {
      return null;
    }
  }
);

export const getVacationRentalsLocation = (state: IStoreState) =>
  state.vacationRentalSearch.vacationRentalsLocation;
