import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";

import { TravelWalletSelection } from "./component";
import { withRouter } from "react-router";
import {
  getCredit,
  getCreditToApply,
  getFetchApplicableTravelWalletItemsCallState,
  getOffers,
  getOfferToApply,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getBestOfferOverall,
  getVacationRentalsIsStackedTravelWalletPaymentOnly,
  getVacationRentalsIsTravelWalletCreditPaymentOnly,
  getVacationRentalsIsTravelWalletOfferPaymentOnly,
  getVacationRentalsMaxApplicableTravelWalletCreditAmount,
} from "../../reducer";
import {
  setOfferToApply,
  setSelectedRewardsPaymentTotal,
  resetPaymentCardSelectedAccounts,
  setCreditToApply,
} from "../../actions/actions";
import {
  getCreditBreakdown,
  getTravelCreditsHistoryTransaction,
} from "../../../travel-wallet/reducer";
import { getRewardsAccounts } from "../../../rewards/reducer";
const mapStateToProps = (state: IStoreState) => {
  return {
    offers: getOffers(state),
    offerToApply: getOfferToApply(state),
    fetchApplicableTravelWalletItemsCallState:
      getFetchApplicableTravelWalletItemsCallState(state),
    selectedPaymentMethodId: getSelectedPaymentMethodId(state) ?? "",
    selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
    credits: getCredit(state),
    creditBreakdown: getCreditBreakdown(state),
    creditsToApply: getCreditToApply(state),
    isStackedTravelWalletPaymentOnly:
      getVacationRentalsIsStackedTravelWalletPaymentOnly(state),
    isTravelCreditPaymentOnly:
      getVacationRentalsIsTravelWalletCreditPaymentOnly(state),
    isTravelWalletOfferPaymentOnly:
      getVacationRentalsIsTravelWalletOfferPaymentOnly(state),
    creditsHistoryTransaction: getTravelCreditsHistoryTransaction(state),
    bestOverallOffer: getBestOfferOverall(state),
    rewardsAccounts: getRewardsAccounts(state),
    maxApplicableTravelWalletCredit:
      getVacationRentalsMaxApplicableTravelWalletCreditAmount(state),
  };
};

export const mapDispatchToProps = {
  setOfferToApply,
  resetPaymentCardSelectedAccounts,
  setSelectedRewardsPaymentTotal,
  setCreditToApply,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TravelWalletSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedTravelWalletSelection = withRouter(
  connector(TravelWalletSelection)
);
