export const SET_LOCATION = "vacationRentalSearch/SET_LOCATION";
export type SET_LOCATION = typeof SET_LOCATION;

export const SET_FROM_DATE = "vacationRentalSearch/SET_FROM_DATE";
export type SET_FROM_DATE = typeof SET_FROM_DATE;

export const SET_UNTIL_DATE = "vacationRentalSearch/SET_UNTIL_DATE";
export type SET_UNTIL_DATE = typeof SET_UNTIL_DATE;

export const SET_OCCUPANCY_COUNTS = "vacationRentalSearch/SET_OCCUPANCY_COUNTS";
export type SET_OCCUPANCY_COUNTS = typeof SET_OCCUPANCY_COUNTS;

export const SET_VACATION_RENTALS_LOCATION =
  "vacationRentalSearch/SET_VACATION_RENTALS_LOCATION";
export type SET_VACATION_RENTALS_LOCATION =
  typeof SET_VACATION_RENTALS_LOCATION;
