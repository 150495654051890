import React, { useContext, useState, useCallback, useEffect } from "react";
import queryStringParser from "query-string";

// components
import { Box, Typography, Button } from "@material-ui/core";
// TODO: Add back in when ready. import { DesktopShop } from "../../../shop/components/DesktopShop";
import {
  IContactInfo,
  TravelerSelectStep,
  DesktopPopupModal,
  CheckoutStepper,
  ButtonWrap,
  IconName,
  Icon,
  TreesConfirmation,
  InformationalModal as TreesModal,
  usePrevious,
} from "halifax";
import { AGENT_FEE, PaymentCard } from "../index";
import { SelectedVacationRentalSummary } from "../SelectedVacationRentalSummary";
import { VacationRentalBookTravellerSection } from "../VacationRentalBookTravellerSection";
// import { ListingTermsInfo } from "../../../vacation-rental-shop/components/ListingTermsInfo";
import { VacationRentalPriceBreakdown } from "../VacationRentalPriceBreakdownCard";

// types, constants
import { DesktopVacationRentalsBookWorkflowConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { CallState, IPerson, ITravelerStepErrors } from "redmond";
import {
  BOOK_BUTTON_TEXT,
  REQUIRED_DETAILS_TEXT,
  AGENT_DETAILS_MODAL_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_SUBTITLE,
} from "./textConstants";

// context
import { ClientContext } from "../../../../App";

// helpers, utils
import { fetchCustomerDetails } from "../../../../api/v0/customer/fetchCustomerDetails";
import {
  PATH_STAYS,
  PATH_VACATION_RENTALS_BOOK_CONFIRMATION,
} from "../../../../utils/paths";
import clsx from "clsx";

// styles
import "./styles.scss";
import { VacationRentalTerms } from "../../../common/VacationRentalTerms/component";
import { VacationRentalImportantInformation } from "../../../common/VacationRentalImportantInformation/component";

export interface IDesktopVacationRentalsBookWorkflowProps
  extends RouteComponentProps,
    DesktopVacationRentalsBookWorkflowConnectorProps {
  isTreesModalExperiment: boolean;
  isTravelWalletOfferExperiment: boolean;
  isCreditAndOfferStackingExperimentV1: boolean;
  isTravelCreditHistoryExperiment: boolean;
  primaryTraveler?: IPerson;
}

export const DesktopVacationRentalsBookWorkflow = ({
  primaryTraveler,
  setContactInfo,
  combinedBookingSteps,
  reservation,
  history,
  email,
  phoneNumber,
  isTravelerStepComplete,
  isContactStepComplete,
  priceQuoteErrors,
  confirmationDetailsErrors,
  fetchApplicableTravelWalletItems,
  fetchTravelWalletCreditHistory,
  selectedHome,
  isTreesModalExperiment,
  isTravelWalletOfferExperiment,
  isTravelCreditHistoryExperiment,
  isBookingValid,
  allStepsCompleted,
  priceQuote,
  scheduleVacationRentalPriceQuote,
  setUserSelectedPassengerIds,
  scheduleVacationRentalsBook,
  addMainGuest,
  addMainGuestCallState,
  userSelectedTraveler,
  setNotifyIfShopAndBookPriceDifferFlag,
  vacationRentalsSchedulePriceQuoteCallState,
}: IDesktopVacationRentalsBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal } = clientContext;

  const isFirstStepCompletedInCheckout =
    isContactStepComplete && isTravelerStepComplete;

  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    phone: false,
    email: false,
    travelerSelect: false,
  });
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);

  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: phoneNumber || "",
    email: email || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    useState<boolean>(true);
  const [disableEditTravelerInfo, setDisableEditTravelerInfo] = useState(
    !!primaryTraveler
  );
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const prevCustomerContactLoading = usePrevious(customerDetailsLoading);

  const onBookVacationRentals = () => {
    const queryString = queryStringParser.parse(history.location.search);
    !!priceQuote
      ? scheduleVacationRentalsBook({
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          isRecommended: queryString.recommended === "true",
        })
      : scheduleVacationRentalPriceQuote({
          history,
          emailAddress: email ?? "",
          agentFee: isAgentPortal ? AGENT_FEE : 0,
        });
  };

  useEffect(() => {
    if (reservation) {
      history.push(PATH_VACATION_RENTALS_BOOK_CONFIRMATION);
    }
  }, [reservation]);

  useEffect(() => {
    if (priceQuoteErrors.length > 0 || confirmationDetailsErrors.length > 0) {
      setDisableEditTravelerInfo(false);
    }
  }, [priceQuoteErrors, confirmationDetailsErrors]);

  useEffect(() => {
    if (
      !customerDetailsLoading &&
      email &&
      prevCustomerContactLoading !== customerDetailsLoading // Note: Run PQ only automatically upon landing on page (after we get customer info) or else this runs everytime email input field is updated
    ) {
      scheduleVacationRentalPriceQuote({
        history,
        emailAddress: email,
        agentFee: isAgentPortal ? AGENT_FEE : 0,
        pollQuoteOnly: true,
      });
    }
  }, [customerDetailsLoading, email, prevCustomerContactLoading]);

  useEffect(() => {
    if (priceQuote && email) {
      addMainGuest({
        emailAddress: email,
        mainGuest: userSelectedTraveler
          ? { ...userSelectedTraveler, phoneNumber }
          : null,
      });
    }
  }, [priceQuote]);

  useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(
          contactInfo?.email || "",
          `${
            details?.phoneNumber?.includes("+")
              ? ""
              : contactInfo?.countryCode ?? "+1"
          }${details?.phoneNumber}` || "" // We default to "+1" in `PhoneInputField` if there isn't a countrycode saved
        );
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
    isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
  }, []);

  useEffect(() => {
    if (
      !customerDetailsLoading &&
      sessionInfo &&
      (prevCustomerContactLoading !== customerDetailsLoading || // Note: Only check to set disable edit traveler info as false after first render (after customer info loads) or after user hits save & continue
        saveButtonClicked)
    ) {
      if (
        !primaryTraveler ||
        !contactInfo?.email ||
        !contactInfo?.phoneNumber
      ) {
        setDisableEditTravelerInfo(false);
      } else {
        setUserSelectedPassengerIds({
          userSelectedPassengerIds: [primaryTraveler.id],
        });
        setDisableEditTravelerInfo(true);
      }
    }
  }, [
    primaryTraveler,
    customerDetailsLoading,
    contactInfo,
    sessionInfo,
    prevCustomerContactLoading,
    saveButtonClicked,
  ]);

  const renderLogo = (
    <ButtonWrap
      className="logo"
      onClick={() => {
        history.push(PATH_STAYS);
      }}
    >
      {clientContext.logo}
    </ButtonWrap>
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleBookingInfoClick = useCallback(() => {
    setDetailsModalOpen(!detailsModalOpen);
  }, [detailsModalOpen]);

  const summaryPanelActions = isAgentPortal ? (
    <Typography
      role="button"
      variant="h6"
      className="view-premier-collection-information"
      onClick={handleBookingInfoClick}
    >
      {AGENT_DETAILS_MODAL_TEXT}
    </Typography>
  ) : null;

  if (!selectedHome) {
    return null;
  }

  return (
    <Box className={clsx("desktop-vacation-rentals-book-workflow-root")}>
      {detailsModalOpen && (
        <DesktopPopupModal
          open={detailsModalOpen}
          onClose={handleBookingInfoClick}
          className="desktop-vacation-rentals-book-details-modal"
        >
          {/* TODO: Add back in when this component is ready. */}
          {/* <DesktopShop hideRightColumn hideProgressbar /> */}
        </DesktopPopupModal>
      )}
      <CheckoutStepper
        steps={combinedBookingSteps}
        headerCopy={BOOK_BUTTON_TEXT}
        subHeaderCopy={REQUIRED_DETAILS_TEXT}
        logo={renderLogo}
        className={clsx("b2b", "combined-step")}
      />
      <Box className="desktop-vacation-rentals-book-workflow-container">
        <Box className={clsx("checkout-template-column", "left")}>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "vacation-rental"
            )}
          >
            <SelectedVacationRentalSummary
              actions={summaryPanelActions}
              onUpdateDates={() => {
                setNotifyIfShopAndBookPriceDifferFlag(false);
                scheduleVacationRentalPriceQuote({
                  history,
                  emailAddress: email ?? "",
                  agentFee: isAgentPortal ? AGENT_FEE : 0,
                });
              }}
              onUpdateOccupancy={() => {
                setNotifyIfShopAndBookPriceDifferFlag(false);
                scheduleVacationRentalPriceQuote({
                  history,
                  emailAddress: email ?? "",
                  agentFee: isAgentPortal ? AGENT_FEE : 0,
                });
              }}
            />
          </Box>

          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "traveler"
            )}
          >
            <VacationRentalBookTravellerSection
              disableEditTravelerInfo={disableEditTravelerInfo}
              setDisableEditTravelerInfo={setDisableEditTravelerInfo}
              travelerWorkflowStep={travelerWorkflowStep}
              setTravelerWorkflowStep={setTravelerWorkflowStep}
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              saveButtonClicked={saveButtonClicked}
              contactInfo={contactInfo}
              setContact={setContact}
              customerDetailsLoading={customerDetailsLoading}
              isFirstStepCompletedInCheckout={isFirstStepCompletedInCheckout}
              setSaveButtonClicked={setSaveButtonClicked}
            />
          </Box>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "credit"
            )}
          >
            <PaymentCard disabled={false} />
            {isTreesModalExperiment && (
              <TreesModal
                image={TreesConfirmation}
                header={TREES_MODAL_HEADER}
                title={TREES_MODAL_TITLE}
                onClose={() => setTreeModalOpen(false)}
                subtitle={TREES_MODAL_SUBTITLE}
                icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
                openModal={treeModalOpen}
                setOpenModal={setTreeModalOpen}
                modalLinkCopy={TREES_MODAL_CTA_TEXT}
                modalButtonCopyStartIcon={
                  <Icon className="trees-icon" name={IconName.TreesIcon} />
                }
                boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
              />
            )}
          </Box>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "terms"
            )}
          >
            <VacationRentalTerms
              cancellationPolicy={
                selectedHome.availability.rate?.cancellationPolicy?.policy
              }
              checkInTimes={selectedHome.listing.terms.checkInTimes}
              checkOutTimes={selectedHome.listing.terms.checkOutTimes}
              checkinInformation={selectedHome.listing.terms.checkinInformation}
              petFriendly={selectedHome.listing.terms.petFriendly}
              petsPolicy={selectedHome.listing.terms.petsPolicy}
              paymentPolicy={priceQuote?.rate.paymentPolicy}
            />
          </Box>
          {selectedHome.listing.terms.importantInformation && (
            <Box
              className={clsx(
                "checkout-template-card-content-container",
                "important-info"
              )}
            >
              <VacationRentalImportantInformation
                importantInformation={
                  selectedHome.listing.terms.importantInformation
                }
              />
            </Box>
          )}
        </Box>
        <Box className={clsx("checkout-template-column", "right")}>
          <Box className="checkout-template-column-right-wrapper">
            {vacationRentalsSchedulePriceQuoteCallState !==
              CallState.NotCalled && (
              <Box className="vacation-rentals-book-right-content">
                <Box
                  className={clsx(
                    "checkout-template-card-content-container",
                    "payment-break-down"
                  )}
                >
                  <VacationRentalPriceBreakdown />
                </Box>
                <Box className="confirm-book-button-container">
                  <Button
                    className="confirm-book-button"
                    disabled={
                      !isBookingValid ||
                      !allStepsCompleted ||
                      addMainGuestCallState != CallState.Success
                    }
                    onClick={onBookVacationRentals}
                  >
                    {BOOK_BUTTON_TEXT}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
