import React from "react";
import { AmenityIcon, Icon, IconName } from "halifax";
import { VacationRentalAmenityKindEnum } from "redmond";
import { AmenityKindEnum } from "redmond/apis/tysons/vacation-rentals";

export const MOST_POPULAR_AMENITIES: AmenityKindEnum[] = [
  AmenityKindEnum.Kitchen,
  AmenityKindEnum.AirConditioning,
  AmenityKindEnum.Wifi,
  AmenityKindEnum.Washer,
  AmenityKindEnum.CoffeeMaker,
  // AmenityKindEnum.Dishwasher,
  AmenityKindEnum.Parking,
  AmenityKindEnum.Dryer,
  AmenityKindEnum.Pool,
  AmenityKindEnum.OutdoorSpace,
  AmenityKindEnum.BarbecueGrill,
  AmenityKindEnum.HotTub,
  AmenityKindEnum.Gym,
  AmenityKindEnum.Accessibility,
  AmenityKindEnum.PetFriendly,
  AmenityKindEnum.FreeParking,
  // AmenityKindEnum.FirePit,
  // AmenityKindEnum.Sauna,
];

export enum AmenityCategoryEnum {
  General = "General",
  Bathroom = "Bathroom",
  Bedroom = "Bedroom",
  TechMedia = "Tech and Media",
  Pets = "Pets",
  Parking = "Parking",
  SafetySecurity = "Safety & Security ",
  Cleaning = "Cleaning",
}

export const AmenityCategoryOrder = Object.values(AmenityCategoryEnum);

export const AmenityCategoryIconMap = {
  [AmenityCategoryEnum.General]: (
    <Icon name={IconName.InfoCircle} className="icon-general" />
  ),
  [AmenityCategoryEnum.Pets]: (
    <AmenityIcon amenity={VacationRentalAmenityKindEnum.PetFriendly} />
  ),
  [AmenityCategoryEnum.Bathroom]: (
    <Icon name={IconName.BathTubOutlineIcon} className="icon-bathroom" />
  ),
  [AmenityCategoryEnum.Parking]: (
    <AmenityIcon amenity={VacationRentalAmenityKindEnum.Parking} />
  ),
  [AmenityCategoryEnum.Bedroom]: (
    <Icon name={IconName.BedOutlineIcon} className="icon-bedroom" />
  ),
  [AmenityCategoryEnum.SafetySecurity]: (
    <AmenityIcon amenity={VacationRentalAmenityKindEnum.SecuritySystem} />
  ),
  [AmenityCategoryEnum.TechMedia]: (
    <Icon name={IconName.BedOutlineIcon} className="icon-bedroom" />
  ),
  [AmenityCategoryEnum.Cleaning]: (
    <AmenityIcon amenity={VacationRentalAmenityKindEnum.Washer} />
  ),
} as any;

export const amenityKindToCategoryMap = {
  [AmenityKindEnum.CoffeeTea]: AmenityCategoryEnum.Bedroom,
  [AmenityKindEnum.Laundry]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.SecureEntry]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.Washer]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.PropertyView]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Dryer]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.MealAvailable]: AmenityCategoryEnum.General,
  [AmenityKindEnum.NatureView]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Hdtv]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.FreeParking]: AmenityCategoryEnum.Parking,
  [AmenityKindEnum.SmokingAllowed]: AmenityCategoryEnum.General,
  [AmenityKindEnum.CityView]: AmenityCategoryEnum.General,
  [AmenityKindEnum.SecuritySystem]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.DedicatedWorkspace]: AmenityCategoryEnum.Bedroom,
  [AmenityKindEnum.AccessibleBathroom]: AmenityCategoryEnum.Bathroom,
  [AmenityKindEnum.GamingConsole]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.ConvenientCheckInOut]: AmenityCategoryEnum.General,
  [AmenityKindEnum.PaidParking]: AmenityCategoryEnum.Parking,
  [AmenityKindEnum.Kitchen]: AmenityCategoryEnum.General,
  [AmenityKindEnum.CookingBasics]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Gym]: AmenityCategoryEnum.General,
  [AmenityKindEnum.BeachView]: AmenityCategoryEnum.General,
  [AmenityKindEnum.PetFriendly]: AmenityCategoryEnum.Pets,
  [AmenityKindEnum.Entertainment]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.CarbonMonoxideAlarm]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.FitnessCenter]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Crib]: AmenityCategoryEnum.Bedroom,
  [AmenityKindEnum.BeachFront]: AmenityCategoryEnum.General,
  [AmenityKindEnum.BarbecueGrill]: AmenityCategoryEnum.General,
  [AmenityKindEnum.KidFriendly]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.HotTub]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Accessibility]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.AccessibleFacilities]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.AirConditioning]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Microwave]: AmenityCategoryEnum.General,
  [AmenityKindEnum.OutdoorSpace]: AmenityCategoryEnum.General,
  [AmenityKindEnum.InternetAccess]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.Coffee]: AmenityCategoryEnum.General,
  [AmenityKindEnum.AllergyFriendly]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.Waterfront]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Housekeeping]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.CoffeeMaker]: AmenityCategoryEnum.General,
  [AmenityKindEnum.MountainView]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Unspecified]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Parking]: AmenityCategoryEnum.Parking,
  [AmenityKindEnum.Pool]: AmenityCategoryEnum.General,
  [AmenityKindEnum.SecureStorage]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.CleaningProducts]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.Iron]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.Heating]: AmenityCategoryEnum.General,
  [AmenityKindEnum.WaterView]: AmenityCategoryEnum.General,
  [AmenityKindEnum.HairDryer]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.SmokeAlarm]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.Wifi]: AmenityCategoryEnum.General,
  // [AmenityKindEnum.Bathtub]: AmenityCategoryEnum.Bathroom,
  // [AmenityKindEnum.BarInBuilding]: AmenityCategoryEnum.General,
};
