import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingSuccessModal } from "./component";
import {
  getEarnedString,
  getConfirmationEmail,
  getVacationRentalConfirmationDetails,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import { getVacationRentalShopSelectedListing } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => ({
  earnString: getEarnedString(state),
  confirmationEmail: getConfirmationEmail(state),
  vrReservation: getVacationRentalConfirmationDetails(state),
  selectedHome: getVacationRentalShopSelectedListing(state),
});

const mapDispatchToProps = {
  resetBookState: actions.redoSearch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingSuccessModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingSuccessModal = withRouter(
  connector(BookingSuccessModal)
);
