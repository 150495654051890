export const GUEST_SELECTOR_INPUT_LABEL = (
  adults: number,
  children: number[],
  petsCount?: number
) => {
  const numTravelers = adults + children.length;
  return numTravelers === 1
    ? `${numTravelers} guest${petsCount ? `, ${petsCount} pet` : ""}`
    : `${numTravelers} guests${petsCount ? `, ${petsCount} pet` : ""}`;
};
