import React from "react";
import { MobilePhotoGalleryConnectorProps } from "./container";

import "./styles.scss";
import {
  ActionLink,
  Carousel,
  CloseButtonIcon,
  MobilePopoverCard,
  PhotoLayout,
} from "halifax";
import clsx from "clsx";
// import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { Box, Typography } from "@material-ui/core";
import { ConnectedShopCta } from "../ShopCta/container";
import { IShopCtaVariant } from "../ShopCta/component";

export interface MobilePhotoGalleryProps
  extends MobilePhotoGalleryConnectorProps {}

export const MobilePhotoGallery = (props: MobilePhotoGalleryProps) => {
  const { listing } = props;

  const [openModal, setOpenModal] = React.useState(false);

  const filteredImages = (listing?.listing?.content?.media ?? [])
    .filter((image) => !!image)
    .map((image) => image.url);
  return (
    <div className="mobile-vr-photo-modal">
      <Box display="flex" height="224px" onClick={() => setOpenModal(true)}>
        <Carousel
          className="carousel-overrides"
          imageUrlsArray={filteredImages}
          hideDots={false}
          hideArrows={false}
        />
      </Box>
      <MobilePopoverCard
        className={clsx("mobile-vr-photo-modal-popup")}
        open={openModal}
        onClose={(event: React.MouseEvent) => {
          event.stopPropagation();
          setOpenModal(false);
        }}
        topRightButton={
          <ActionLink
            className={clsx("mobile-vr-photo-modal-popup-button", "close")}
            content={<CloseButtonIcon />}
            label="Close"
            onClick={() => setOpenModal(false)}
          />
        }
        bottomButton={
          <ConnectedShopCta variant={IShopCtaVariant.CTA_BUTTON_ONLY} />
        }
        fullScreen
      >
        <Typography className="all-photos-title">
          All photos ({filteredImages.length})
        </Typography>
        <div
          id="mobile-photo-gallery"
          className="mobile-vr-photo-modal-container"
        >
          <PhotoLayout
            imgUrls={filteredImages}
            scrollableTarget="mobile-photo-gallery"
            className="mobile-vr-photo-layout"
          />
        </div>
      </MobilePopoverCard>
    </div>
  );
};
