import { exhaustiveReducer } from "halifax";
import { actions, actionTypes } from "../actions";
import { IVacationRentalAvailabilityState } from "./state";

export const initialState: IVacationRentalAvailabilityState = {
  openDatesModal: false,
  searchFromDate: null,
  searchUntilDate: null,
  searchAdultsCount: 2,
  searchChildrenCount: [],
  searchPetsCount: 0,
};

export const reducer = exhaustiveReducer(
  initialState,
  (state, action: actions.VacationRentalAvailabilityActions) => {
    switch (action.type) {
      case actionTypes.SET_OPEN_DATES_MODAL:
        return {
          ...state,
          openDatesModal: action.openDatesModal,
        };
      case actionTypes.SET_SEARCHED_DATES:
        return {
          ...state,
          searchFromDate: action.searchedFromDate,
          searchUntilDate: action.searchedUntilDate,
        };
      case actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS:
        const { adults, children, pets } = action.counts;
        return {
          ...state,
          searchAdultsCount: adults,
          searchChildrenCount: children,
          searchPetsCount: pets ?? 0,
        };
    }
  }
);

export * from "./selectors";
