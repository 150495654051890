import { getAgentErrorSubtitle, getAgentErrorTitle } from "halifax";
import { ErrorTitles } from "redmond";
import { HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum } from "redmond/apis/tysons/vacation-rentals";

const GENERIC_ERROR_TITLE = "Uh oh! Something went wrong";
const GENERIC_ERROR_SUBTITLE = "Please search again";

// CHECKOUT COMPLETION TEXT
export const ADD_TRAVELERS = "Add Travelers";
export const TRAVELER_INFORMATION = "Traveler Information";
export const CONTACT = "Contact Information";
export const REWARDS_AND_PAYMENT = "Rewards & payment";
export const CORP_REWARDS_AND_PAYMENT_SHORTENED = "Payment";

// BUTTON_TEXT
export const CONTINUE = "Continue";
export const TRY_AGAIN = "Try Again";
export const SEARCH_AGAIN = "Search Again";
export const CHOOSE_ANOTHER_HOTEL = "Choose Another Hotel";
export const CHOOSE_ANOTHER_RATE = "Choose Another Rate";
export const CONFIRM_AND_BOOK = "Confirm and Book";
export const UPDATE_PAYMENT_INFO = "Update Payment";
export const EDIT_PAYMENT_INFO = "Edit Payment Information";
export const MODIFY_TRAVELERS = "Modify Travelers";
export const CHOOSE_ANOTHER_PROPERTY = "Choose Another Property";

// Icon types
export const ERROR_ICON = "error";
export const UNABLED_ICON = "unabled";
export const PRICE_DECREASE_ICON = "decreased";
export const PRICE_INCREASE_ICON = "increased";

export const GENERIC_ERROR_TITLES: ErrorTitles = {
  title: GENERIC_ERROR_TITLE,
  subtitle: GENERIC_ERROR_SUBTITLE,
  primaryButtonText: SEARCH_AGAIN,
  secondaryButtonText: CHOOSE_ANOTHER_HOTEL,
};

export const GENERIC_VR_ERROR_TITLES: ErrorTitles = {
  title: GENERIC_ERROR_TITLE,
  subtitle: GENERIC_ERROR_SUBTITLE,
  primaryButtonText: SEARCH_AGAIN,
  secondaryButtonText: CHOOSE_ANOTHER_PROPERTY,
};

export const USER_CARD_ERROR_TITLES: ErrorTitles = {
  icon: ERROR_ICON,
  title: "We were unable to process your payment method.",
  subtitle:
    "Choose another payment method, or delete your original payment method and add it again. If the problem continues, please contact Customer Support at 844-422-6922 for further assistance.",
  primaryButtonText: CONTINUE,
};

export const INACTIVITY_TITLES: ErrorTitles = {
  title: "Oh no. Your booking has timed out.",
  subtitle:
    "It looks like you've been away for a little while. Please re-select your details and try again.",
  primaryButtonText: CONTINUE,
  icon: ERROR_ICON,
};

export const PAYMENT_ERROR_TITLES: ErrorTitles = {
  title: "Oh no - we couldn't confirm your booking",
  subtitle: "Please try again or select a new payment method",
  secondaryButtonText: "Edit payment method",
  primaryButtonText: TRY_AGAIN,
  icon: ERROR_ICON,
};

export const LACK_OF_INVENTORY_TITLES: ErrorTitles = {
  title: "Oh no - this price is no longer available",
  subtitle:
    "Prices can change frequently: please try again in a few hours or try another hotel.",
  secondaryButtonText: CHOOSE_ANOTHER_HOTEL,
  primaryButtonText: TRY_AGAIN,
  icon: ERROR_ICON,
};

export const RATE_NOT_AVAILABLE_TITLES: ErrorTitles = {
  title: "Oh no - this rate is no longer available",
  subtitle: "Please select another rate to continue your stay",
  secondaryButtonText: CHOOSE_ANOTHER_HOTEL,
  primaryButtonText: CHOOSE_ANOTHER_RATE,
  icon: ERROR_ICON,
};

export const REDEMPTION_FAILED_TITLES: ErrorTitles = {
  title: "Rewards Redemption Failure",
  subtitle:
    "We were unable to redeem your rewards at this time. Please try again, and if the issue continues to persist, please try again later.",
  primaryButtonText: TRY_AGAIN,
  icon: UNABLED_ICON,
};

export const FRAUD_TITLES: ErrorTitles = {
  icon: UNABLED_ICON,
  title: GENERIC_ERROR_TITLE,
  subtitle: `There is an issue with you account. Please contact Capital One Support.`,
  primaryButtonText: SEARCH_AGAIN,
};

export const CHECK_IN_AGE_NOT_MET: ErrorTitles = {
  title: "Check-In Minimum Age Not Met",
  subtitle: `The selected traveler does not meet the hotel’s minimum age requirements for check-in`,
  primaryButtonText: MODIFY_TRAVELERS,
  secondaryButtonText: CHOOSE_ANOTHER_HOTEL,
  icon: ERROR_ICON,
};

export const FEE_KIND_LABEL_MAPPING: Record<
  HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum,
  string
> = {
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.CityTax]: "City Tax",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.CleaningFee]:
    "Cleaning Fee",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.Fee]: "Fee",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.HopperServiceFee]:
    "Service Fee",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.HotelFee]: "Hotel Fee",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.OccupancyFee]:
    "Occupancy Fee",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.PetFee]: "Pet Fee",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.PropertyFee]:
    "Property Fee",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.RecoveryChargesTax]:
    "Recovery Charges Tax",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.ResortFee]: "Resort Fee",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.SalesTax]: "Sales Tax",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.Tax]: "Tax",
  [HomesPriceQuoteRateDetailedPriceFeesInnerKindEnum.TravelerServiceFee]:
    "Traveler Service Fee",
};

export const PAYMENT_METHOD_ERROR_TITLES = (error: string): ErrorTitles => {
  switch (error) {
    case "InvalidCVV":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: ERROR_ICON,
        title: "CVV Error",
        subtitle:
          "Ensure your CVV / Security Code is entered correctly and try again.",
        primaryButtonText: EDIT_PAYMENT_INFO,
      };
    case "InvalidAddress":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: ERROR_ICON,
        title: "Address Verification Error",
        subtitle:
          "Ensure your Zip Code and Country is entered correctly and try again.",
        primaryButtonText: EDIT_PAYMENT_INFO,
      };
    default:
    case "GenericError":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: ERROR_ICON,
        title: "Error Adding Payment Method",
        subtitle:
          "We were unable to add your payment method. Please ensure all of your information is correct and try again.",
        primaryButtonText: EDIT_PAYMENT_INFO,
      };
  }
};

// Pricing

export const ROOM_TOTAL = (nights: number | null, roomsCount: number = 1) => {
  const roomsText =
    roomsCount && roomsCount <= 1 ? "Room" : `${roomsCount} Rooms`;
  const nightText = nights && nights <= 1 ? "night" : "nights";

  return `${roomsText} x ${nights} ${nightText}`;
};

export const VACATION_RENTAL_TOTAL = (nights: number | null) => {
  const nightText = nights && nights <= 1 ? "night" : "nights";

  return `Vacation Rental x ${nights} ${nightText}`;
};

export const TAXES_AND_FEES = "Taxes & Fees";
export const DUE_TODAY = "Due Today";
export const ADDITIONAL_FEES = "Additional Service Fee (Due at check-in)";
export const TOTAL = "Total";
export const REWARDS = "Rewards applied:";
export const REWARDS_BALANCE_REMAINING = "Rewards balance remaining:";
export const AMOUNT_DUE = "Amount due:";
export const TRAVEL_OFFER_APPLIED = "Travel offer applied:";
export const TRAVEL_CREDITS_APPLIED = "Travel credits applied:";
export const TOTAL_TRAVEL_CREDITS_APPLIED = "Total travel credits applied:";

export const EARN_STRING = (amount: number, currency?: string) =>
  `You’ll earn <strong>${amount.toLocaleString("en-US")} ${
    currency || "Miles"
  }</strong> on this trip!`;

export const EARNED_STRING = (amount: number, currency?: string) =>
  `You've earned <strong>${amount.toLocaleString("en-US")} ${
    currency || "Miles"
  }</strong> on this trip!`;

export const getPriceDifferenceTitle = (isIncrease: boolean, amount: string) =>
  `${!isIncrease ? `You're in luck! ` : ``}The price has ${
    isIncrease ? "increased" : "decreased"
  } by $${amount}.`;
export const PRICE_DECREASE_SUBTITLE =
  "Sometimes hotels change the prices unexpectedly. You can review the new trip total on the final screen before checkout.";
export const PRICE_INCREASE_SUBTITLE =
  "Sometimes hotels change the prices unexpectedly. Sorry about that! You can review the new trip total on the final screen before checkout.";
export const PRICE_INCREASE_OFFER_COVERAGE_TITLE =
  "We’ve detected a price increase. Your Travel Offer no longer covers the total cost of your booking.";
export const PRICE_INCREASE_CREDITS_COVERAGE_TITLE =
  "We’ve detected a price increase. Your travel credits no longer cover the total cost of your booking.";
export const PRICE_INCREASE_WALLET_COVERAGE_SUBTITLE =
  "Please add or select a payment method to complete the booking.";
export const VR_PRICE_DECREASE_SUBTITLE =
  "Sometimes Vacation Rental prices can change unexpectedly. You can review the price total on the final screen before checkout.";
export const VR_PRICE_INCREASE_SUBTITLE =
  "Sometimes Vacation Rental prices can change unexpectedly. Sorry about that! You can review the new trip total on the final screen before checkout.";

// CFAR
export const CANCEL_FOR_ANY_REASON = "Cancel for any reason";
