import React from "react";

import { Box, Typography } from "@material-ui/core";
import { RouterProps } from "react-router-dom";

import { ActionButton } from "halifax";

import { PATH_STAYS } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../shop/utils/queryStringHelpers";
import { TitleSummaryCardConnectorProps } from "./container";
import { BACK_TO_RESULTS, SUBTITLE, TITLE } from "./textConstants";

import "./styles.scss";
import { ListingAddressEnum } from "redmond/apis/tysons/vacation-rentals";

export interface ITitleSummaryCardProps
  extends TitleSummaryCardConnectorProps,
    RouterProps {
  isMobile?: boolean;
}

export const TitleSummaryCard = (props: ITitleSummaryCardProps) => {
  const {
    history,
    fromDate,
    untilDate,
    isMobile,
    adultsCount,
    childrenCount,
    petsCount,
    selectedHome,
  } = props;

  const returnToAvailability = () => {
    if (!selectedHome) {
      return history.push(PATH_STAYS);
    }

    const { listing } = selectedHome;

    const listingAddress =
      listing.content.location.address.ListingAddress ===
        ListingAddressEnum.ObfuscatedAddress ||
      listing.content.location.address.ListingAddress ===
        ListingAddressEnum.FullAddress
        ? listing.content.location.address
        : null;

    const search = transformToStringifiedAvailabilityQuery({
      location: `${listingAddress?.city || ""} ${listingAddress?.state || ""}`,
      fromDate,
      untilDate,
      adultsCount,
      childrenCount,
      petsCount,
    });
    history.push(`${PATH_STAYS}${search}`);
  };

  return (
    <Box className="title-summary-container">
      {!isMobile && (
        <ActionButton
          className="back-to-results"
          message={BACK_TO_RESULTS}
          onClick={() => {
            returnToAvailability();
          }}
          defaultStyle="h4r-secondary"
        />
      )}
      <Typography variant="h6" className="title">
        {TITLE}
      </Typography>
      <Typography variant="body1" className="subtitle">
        {SUBTITLE}
      </Typography>
    </Box>
  );
};
