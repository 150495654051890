import axios from "axios";
import { TrackEventRequest } from "redmond";
import { config } from "../../config";

import { analyticsEventApi } from "../paths";

const END_POINT: string = analyticsEventApi;

export const trackEvent = (req: TrackEventRequest): Promise<boolean> =>
  new Promise(async (resolve, reject) => {
    try {
      req.properties = {
        ...req.properties,
        has_travel_credit: req.properties.has_credits,
      };
      const res = await axios.post(END_POINT, req, {
        baseURL: config.baseURL,
      });

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
