import { connect, ConnectedProps } from "react-redux";
import { PickerType } from "halifax";

import { MonthAndDatePicker } from "./component";
import { getVacationRentalShopUnavailableDates } from "../../../../../shop/reducer";
import { IStoreState } from "../../../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  const unavailableDates = getVacationRentalShopUnavailableDates(state);
  return {
    pickerType: PickerType.RANGE,
    ...(unavailableDates ? { unavailableDates } : {}),

  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MonthAndDatePickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMonthAndDatePicker = connector(MonthAndDatePicker);
