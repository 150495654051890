import React, { ReactNode } from "react";
import { BaseAddress, ListingSearchResult } from "redmond";
import "./styles.scss";
import { VacationRentalSummaryCard, getImageUrlsArray } from "halifax";
import { OccupancySelection } from "./components/OccupancySelection";
import { CalendarPickerButton } from "./components/CalendarPickerButton";

export interface SelectedVacationRentalSummaryProps {
  selectedHome: ListingSearchResult | null;
  checkIn: Date | null;
  checkOut: Date | null;
  isMobile?: boolean;
  className?: string;
  actions?: ReactNode;
  onUpdateDates?: () => void;
  onUpdateOccupancy?: () => void;
}

export const SelectedVacationRentalSummary = (
  props: SelectedVacationRentalSummaryProps
) => {
  const {
    selectedHome,
    checkIn,
    checkOut,
    onUpdateDates,
    onUpdateOccupancy,
    isMobile,
  } = props;

  if (!selectedHome || !checkIn || !checkOut) return null;
  const [image] = getImageUrlsArray(selectedHome.listing.content.media);

  return (
    <VacationRentalSummaryCard
      {...props}
      homeName={selectedHome.listing.content.name}
      homeLocation={
        selectedHome.listing.content.location.address as BaseAddress
      }
      listingCollection={selectedHome.listing.listingCollection}
      calendarComponent={
        !isMobile ? (
          <CalendarPickerButton
            hasMissingSearchInfoError={false}
            onClickSubmit={onUpdateDates}
            hideSeparator
          />
        ) : undefined
      }
      occupancySelection={
        !isMobile ? (
          <OccupancySelection onClickSubmit={onUpdateOccupancy} />
        ) : undefined
      }
      cancellationPolicy={selectedHome.availability.rate?.cancellationPolicy}
      displayImage={image}
      numberOfBathrooms={selectedHome.listing.content.layout.numberOfBathrooms}
      numberOfBedrooms={selectedHome.listing.content.layout.numberOfBedrooms}
      maxOccupancy={selectedHome.listing.terms.maxOccupancy}
    />
  );
};
