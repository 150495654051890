export const getUserRatingQualifier = (userRating: number): string => {
  let userRatingQualifer = "";

  if (userRating) {
    if (userRating > 98) {
      userRatingQualifer = "Exceptional";
    } else if (userRating > 95) {
      userRatingQualifer = "Superb";
    } else if (userRating > 90) {
      userRatingQualifer = "Excellent";
    } else if (userRating > 85) {
      userRatingQualifer = "Very Good";
    } else if (userRating > 80) {
      userRatingQualifer = "Good";
    } else if (userRating > 75) {
      userRatingQualifer = "Pleasant";
    } else if (userRating > 70) {
      userRatingQualifer = "Satisfactory";
    } else if (userRating > 65) {
      userRatingQualifer = "Fair";
    } else if (userRating > 60) {
      userRatingQualifer = "Adequate";
    } else if (userRating > 55) {
      userRatingQualifer = "Mediocre";
    } else if (userRating > 50) {
      userRatingQualifer = "Below Average";
    } else if (userRating > 45) {
      userRatingQualifer = "Poor";
    } else {
      userRatingQualifer = "Very Poor";
    }
  }

  return userRatingQualifer;
};
