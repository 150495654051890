import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import {
  PATH_VACATION_RENTALS_BOOK,
  PATH_VACATION_RENTALS_BOOK_CONFIRMATION,
} from "../../utils/paths";
import { useDeviceTypes } from "halifax";
import { Route, RouteComponentProps } from "react-router-dom";
import { CallState, IPerson, REVIEW_DETAILS_VR_CHECKOUT } from "redmond";
import { ClientContext } from "../../App";
import {
  AVAILABLE,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
} from "../../context/experiments";
import { VacationRentalBookBookConnectorProps } from "./container";
import { goToShop } from "../shop/utils/queryStringHelpers";
import { PORTAL_TITLE, VACATION_RENTAL_CHECKOUT_TITLE } from "./textConstants";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import { DesktopVacationRentalsBookWorkflow } from "./components/DesktopVacationRentalsBookWorkflow";
import { MobileVacationRentalsBookWorkflow } from "./components/MobileVacationRentalsBookWorkflow";
import { VRBookingErrorModal } from "./components/VRBookingErrorModal";
import { BookingInProgressModal } from "./components/BookingInProgressModal";
import { BookingSuccessModal } from "./components/BookingSuccessModal";

export interface IVacationRentalBookProps
  extends VacationRentalBookBookConnectorProps,
    RouteComponentProps {}

export const VacationRentalBook = (props: IVacationRentalBookProps) => {
  const {
    history,
    location,
    selectedHome,
    travelers,
    userPassengerCallState,
    viewedVacationRentalDetailsProperties,
  } = props;

  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [primaryTraveler, setPrimaryTraveler] = useState<IPerson | undefined>(
    undefined
  );
  const [searchedPrimaryTraveler, setSearchedPrimaryTraveler] = useState(false);

  const userInfo = useContext(ClientContext).sessionInfo?.userInfo;

  const expState = useExperiments();

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  useEffect(() => {
    if (!selectedHome) {
      goToShop({ history });
    }
  }, [selectedHome]);

  useEffect(() => {
    document.title = VACATION_RENTAL_CHECKOUT_TITLE;
    if (selectedHome) {
      trackEvent({
        eventName: REVIEW_DETAILS_VR_CHECKOUT,
        properties: {
          ...viewedVacationRentalDetailsProperties.properties,
        },
        encryptedProperties: [
          ...viewedVacationRentalDetailsProperties.encryptedProperties,
        ],
      });
    }
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (userPassengerCallState === CallState.Success) {
      const savedPrimaryTraveler = travelers.find(
        (t) =>
          t.givenName === userInfo?.firstName &&
          t.surname === userInfo?.lastName
      );
      setSearchedPrimaryTraveler(true);
      if (!searchedPrimaryTraveler) {
        setPrimaryTraveler(savedPrimaryTraveler);
      }
    }
  }, [travelers, userPassengerCallState, searchedPrimaryTraveler]);

  return (
    <Box
      className={clsx(
        "vacation-rental-book-root",
        {
          confirm:
            location.pathname === PATH_VACATION_RENTALS_BOOK_CONFIRMATION,
        },
        { mobile: matchesMobile }
      )}
    >
      <Route path={PATH_VACATION_RENTALS_BOOK} exact>
        <Box className="vacation-rental-book-container">
          {matchesDesktop && (
            <DesktopVacationRentalsBookWorkflow
              isTreesModalExperiment={isTreesModalExperiment}
              isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
              isCreditAndOfferStackingExperimentV1={
                isCreditAndOfferStackingExperimentV1
              }
              isTravelCreditHistoryExperiment={isTravelCreditHistoryExperiment}
              primaryTraveler={primaryTraveler}
            />
          )}
          {matchesMobile && (
            <MobileVacationRentalsBookWorkflow
              isTreesModalExperiment={isTreesModalExperiment}
              isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
              isTravelWalletCreditsExperiment={isTravelWalletCreditsExperiment}
              isCreditAndOfferStackingExperimentV1={
                isCreditAndOfferStackingExperimentV1
              }
              isTravelCreditHistoryExperiment={isTravelCreditHistoryExperiment}
              primaryTraveler={primaryTraveler}
            />
          )}
        </Box>
        <VRBookingErrorModal />
        <BookingInProgressModal />
      </Route>
      <Route path={[PATH_VACATION_RENTALS_BOOK_CONFIRMATION]}>
        <BookingSuccessModal isTreesModalExperiment={isTreesModalExperiment} />
      </Route>
    </Box>
  );
};
