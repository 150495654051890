// Getters
export const FETCH_USER_PASSENGERS = "vacationRentalBook/FETCH_USER_PASSENGERS";
export type FETCH_USER_PASSENGERS = typeof FETCH_USER_PASSENGERS;

export const LIST_PAYMENT_METHODS = "vacationRentalBook/LIST_PAYMENT_METHODS";
export type LIST_PAYMENT_METHODS = typeof LIST_PAYMENT_METHODS;

export const FETCH_PAYMENT_METHOD = "vacationRentalBook/FETCH_PAYMENT_METHOD";
export type FETCH_PAYMENT_METHOD = typeof FETCH_PAYMENT_METHOD;

// Setters
export const UPDATE_USER_PASSENGER = "vacationRentalBook/UPDATE_USER_PASSENGER";
export type UPDATE_USER_PASSENGER = typeof UPDATE_USER_PASSENGER;

export const DELETE_USER_PASSENGER = "vacationRentalBook/DELETE_USER_PASSENGER";
export type DELETE_USER_PASSENGER = typeof DELETE_USER_PASSENGER;

export const SET_USER_PASSENGERS = "vacationRentalBook/SET_USER_PASSENGERS";
export type SET_USER_PASSENGERS = typeof SET_USER_PASSENGERS;

export const SET_USER_SELECTED_PASSENGER_IDS =
  "vacationRentalBook/SET_USER_SELECTED_PASSENGER_IDS";
export type SET_USER_SELECTED_PASSENGER_IDS =
  typeof SET_USER_SELECTED_PASSENGER_IDS;

export const SET_USER_PASSENGERS_CALL_STATE_FAILED =
  "vacationRentalBook/SET_USER_PASSENGERS_CALL_STATE_FAILED";
export type SET_USER_PASSENGERS_CALL_STATE_FAILED =
  typeof SET_USER_PASSENGERS_CALL_STATE_FAILED;

export const ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE =
  "vacationRentalBook/ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE";
export type ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE =
  typeof ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE;

export const SET_CONTACT_INFO = "vacationRentalBook/SET_CONTACT_INFO";
export type SET_CONTACT_INFO = typeof SET_CONTACT_INFO;

export const SET_PAYMENT_METHODS = "vacationRentalBook/SET_PAYMENT_METHODS";
export type SET_PAYMENT_METHODS = typeof SET_PAYMENT_METHODS;

export const SET_PAYMENT_METHOD = "vacationRentalBook/SET_PAYMENT_METHOD";
export type SET_PAYMENT_METHOD = typeof SET_PAYMENT_METHOD;

export const SET_SELECTED_PAYMENT_METHOD_ID =
  "vacationRentalBook/SET_SELECTED_PAYMENT_METHOD_ID";
export type SET_SELECTED_PAYMENT_METHOD_ID =
  typeof SET_SELECTED_PAYMENT_METHOD_ID;

export const VERIFY_PAYMENT_METHOD = "vacationRentalBook/VERIFY_PAYMENT_METHOD";
export type VERIFY_PAYMENT_METHOD = typeof VERIFY_PAYMENT_METHOD;

export const VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED =
  "vacationRentalBook/VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED";
export type VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED;

export const VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  "vacationRentalBook/VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS";
export type VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  typeof VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS;

export const DELETE_PAYMENT_METHOD = "vacationRentalBook/DELETE_PAYMENT_METHOD";
export type DELETE_PAYMENT_METHOD = typeof DELETE_PAYMENT_METHOD;

export const DELETE_PAYMENT_METHOD_CALL_STATE_FAILED =
  "vacationRentalBook/SET_PAYMENT_METHOD_CALL_STATE_FAILED";
export type DELETE_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof DELETE_PAYMENT_METHOD_CALL_STATE_FAILED;

export const DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  "vacationRentalBook/DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS";
export type DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  typeof DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS;

export const SET_PAYMENT_METHOD_CALL_STATE_FAILED =
  "vacationRentalBook/SET_PAYMENT_METHOD_CALL_STATE_FAILED";
export type SET_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHOD_CALL_STATE_FAILED;

export const SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  "vacationRentalBook/SET_PAYMENT_METHODS_CALL_STATE_FAILED";
export type SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHODS_CALL_STATE_FAILED;

export const CHECK_SESSION = "vacationRentalBook/CHECK_SESSION";
export type CHECK_SESSION = typeof CHECK_SESSION;

export const CHECK_SESSION_CALL_STATE_FAILED =
  "vacationRentalBook/CHECK_SESSION_CALL_STATE_FAILED";
export type CHECK_SESSION_CALL_STATE_FAILED =
  typeof CHECK_SESSION_CALL_STATE_FAILED;

export const SET_SESSION = "vacationRentalBook/SET_SESSION";
export type SET_SESSION = typeof SET_SESSION;

export const CLEAR_SESSION = "vacationRentalBook/CLEAR_SESSION";
export type CLEAR_SESSION = typeof CLEAR_SESSION;

export const SCHEDULE_PRICE_QUOTE = "vacationRentalBook/SCHEDULE_PRICE_QUOTE";
export type SCHEDULE_PRICE_QUOTE = typeof SCHEDULE_PRICE_QUOTE;

export const SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS =
  "vacationRentalBook/SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS";
export type SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS =
  typeof SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS;

export const SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED =
  "vacationRentalBook/SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED";
export type SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED =
  typeof SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED;

export const POLL_PRICE_QUOTE = "vacationRentalBook/POLL_PRICE_QUOTE";
export type POLL_PRICE_QUOTE = typeof POLL_PRICE_QUOTE;

export const SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS =
  "vacationRentalBook/SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS";
export type SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS =
  typeof SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS;

export const SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED =
  "vacationRentalBook/SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED";
export type SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED =
  typeof SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED;

export const SET_PRICE_QUOTE = "vacationRentalBook/SET_PRICE_QUOTE";
export type SET_PRICE_QUOTE = typeof SET_PRICE_QUOTE;

export const POLL_PASSENGER_VALIDATION =
  "vacationRentalBook/POLL_PASSENGER_VALIDATION";
export type POLL_PASSENGER_VALIDATION = typeof POLL_PASSENGER_VALIDATION;

export const POLL_PASSENGER_VALIDATION_CALL_STATE_SUCCESS =
  "vacationRentalBook/POLL_PASSENGER_VALIDATION_CALL_STATE_SUCCESS";
export type POLL_PASSENGER_VALIDATION_CALL_STATE_SUCCESS =
  typeof POLL_PASSENGER_VALIDATION_CALL_STATE_SUCCESS;

export const POLL_PASSENGER_VALIDATION_CALL_STATE_FAILED =
  "vacationRentalBook/POLL_PASSENGER_VALIDATION_CALL_STATE_FAILED";
export type POLL_PASSENGER_VALIDATION_CALL_STATE_FAILED =
  typeof POLL_PASSENGER_VALIDATION_CALL_STATE_FAILED;

export const POLL_PASSENGER_VALIDATION_CALL_STATE_PENDING =
  "vacationRentalBook/POLL_PASSENGER_VALIDATION_CALL_STATE_PENDING";
export type POLL_PASSENGER_VALIDATION_CALL_STATE_PENDING =
  typeof POLL_PASSENGER_VALIDATION_CALL_STATE_PENDING;

export const SET_PASSENGERS_VALID = "vacationRentalBook/SET_PASSENGERS_VALID";
export type SET_PASSENGERS_VALID = typeof SET_PASSENGERS_VALID;

export const SET_PASSENGERS_INVALID =
  "vacationRentalBook/SET_PASSENGERS_INVALID";
export type SET_PASSENGERS_INVALID = typeof SET_PASSENGERS_INVALID;

export const SCHEDULE_PURCHASE = "vacationRentalBook/SCHEDULE_PURCHASE";
export type SCHEDULE_PURCHASE = typeof SCHEDULE_PURCHASE;

export const SET_SCHEDULE_PURCHASE_SUCCESS =
  "vacationRentalBook/SET_SCHEDULE_PURCHASE_SUCCESS";
export type SET_SCHEDULE_PURCHASE_SUCCESS =
  typeof SET_SCHEDULE_PURCHASE_SUCCESS;

export const SET_SCHEDULE_PURCHASE_FAILED =
  "vacationRentalBook/SET_SCHEDULE_PURCHASE_FAILED";
export type SET_SCHEDULE_PURCHASE_FAILED = typeof SET_SCHEDULE_PURCHASE_FAILED;

export const SCHEDULE_BOOK = "vacationRentalBook/SCHEDULE_BOOK";
export type SCHEDULE_BOOK = typeof SCHEDULE_BOOK;

export const SET_SCHEDULE_BOOK_SUCCESS =
  "vacationRentalBook/SET_SCHEDULE_BOOK_SUCCESS";
export type SET_SCHEDULE_BOOK_SUCCESS = typeof SET_SCHEDULE_BOOK_SUCCESS;

export const SET_SCHEDULE_BOOK_FAILED =
  "vacationRentalBook/SET_SCHEDULE_BOOK_FAILED";
export type SET_SCHEDULE_BOOK_FAILED = typeof SET_SCHEDULE_BOOK_FAILED;

export const POLL_CONFIRMATION_DETAILS =
  "vacationRentalBook/POLL_CONFIRMATION_DETAILS";
export type POLL_CONFIRMATION_DETAILS = typeof POLL_CONFIRMATION_DETAILS;

export const POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS =
  "vacationRentalBook/POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS";
export type POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS =
  typeof POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS;

export const POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED =
  "vacationRentalBook/POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED";
export type POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED =
  typeof POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED;

export const POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING =
  "vacationRentalBook/POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING";
export type POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING =
  typeof POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING;

export const SET_CONFIRMATION_DETAILS =
  "vacationRentalBook/SET_CONFIRMATION_DETAILS";
export type SET_CONFIRMATION_DETAILS = typeof SET_CONFIRMATION_DETAILS;

export const POLL_FINALIZED = "vacationRentalBook/POLL_FINALIZED";
export type POLL_FINALIZED = typeof POLL_FINALIZED;

export const POLL_FINALIZED_CALL_STATE_SUCCESS =
  "vacationRentalBook/POLL_FINALIZED_CALL_STATE_SUCCESS";
export type POLL_FINALIZED_CALL_STATE_SUCCESS =
  typeof POLL_FINALIZED_CALL_STATE_SUCCESS;

export const POLL_FINALIZED_CALL_STATE_FAILED =
  "vacationRentalBook/POLL_FINALIZED_CALL_STATE_FAILED";
export type POLL_FINALIZED_CALL_STATE_FAILED =
  typeof POLL_FINALIZED_CALL_STATE_FAILED;

export const POLL_FINALIZED_CALL_STATE_PENDING =
  "vacationRentalBook/POLL_FINALIZED_CALL_STATE_PENDING";
export type POLL_FINALIZED_CALL_STATE_PENDING =
  typeof POLL_FINALIZED_CALL_STATE_PENDING;

export const SET_FINALIZED = "vacationRentalBook/SET_FINALIZED";
export type SET_FINALIZED = typeof SET_FINALIZED;

export const REDO_SEARCH = "vacationRentalBook/REDO_SEARCH";
export type REDO_SEARCH = typeof REDO_SEARCH;

export const ACKNOWLEDGE_PRICE_DIFFERENCE =
  "vacationRentalBook/ACKNOWLEDGE_PRICE_DIFFERENCE";
export type ACKNOWLEDGE_PRICE_DIFFERENCE = typeof ACKNOWLEDGE_PRICE_DIFFERENCE;

export const POPULATE_BOOK_QUERY_PARAMS =
  "vacationRentalBook/POPULATE_BOOK_QUERY_PARAMS";
export type POPULATE_BOOK_QUERY_PARAMS = typeof POPULATE_BOOK_QUERY_PARAMS;

export const SET_BOOK_QUERY_PARAMS = "vacationRentalBook/SET_BOOK_QUERY_PARAMS";
export type SET_BOOK_QUERY_PARAMS = typeof SET_BOOK_QUERY_PARAMS;

export const SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID =
  "vacationRentalBook/SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID";
export type SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID =
  typeof SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID;

export const SET_SELECTED_REWARDS_PAYMENT_TOTAL =
  "vacationRentalBook/SET_SELECTED_REWARDS_PAYMENT_TOTAL";
export type SET_SELECTED_REWARDS_PAYMENT_TOTAL =
  typeof SET_SELECTED_REWARDS_PAYMENT_TOTAL;

export const FETCH_PRODUCT_TO_EARN = "vacationRentalBook/FETCH_PRODUCT_TO_EARN";
export type FETCH_PRODUCT_TO_EARN = typeof FETCH_PRODUCT_TO_EARN;

export const SET_PRODUCT_EARN_VALUE =
  "vacationRentalBook/SET_PRODUCT_EARN_VALUE";
export type SET_PRODUCT_EARN_VALUE = typeof SET_PRODUCT_EARN_VALUE;

export const REWARDS_CONVERSION_FAILED =
  "vacationRentalBook/REWARDS_CONVERSION_FAILED";
export type REWARDS_CONVERSION_FAILED = typeof REWARDS_CONVERSION_FAILED;

export const RESET_BOOK_ERRORS = "vacationRentalBook/RESET_BOOK_ERRORS";
export type RESET_BOOK_ERRORS = typeof RESET_BOOK_ERRORS;

export const SET_OFFER_TO_APPLY = "vacationRentalBook/SET_OFFER_TO_APPLY";
export type SET_OFFER_TO_APPLY = typeof SET_OFFER_TO_APPLY;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS =
  "vacationRentalBook/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS =
  "vacationRentalBook/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE =
  "vacationRentalBook/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE;

export const SET_TRAVEL_WALLET_OFFERS =
  "vacationRentalBook/SET_TRAVEL_WALLET_OFFERS";
export type SET_TRAVEL_WALLET_OFFERS = typeof SET_TRAVEL_WALLET_OFFERS;

export const SET_BEST_OFFER_OVERALL =
  "vacationRentalBook/SET_BEST_OFFER_OVERALL";
export type SET_BEST_OFFER_OVERALL = typeof SET_BEST_OFFER_OVERALL;

export const SET_TRAVEL_WALLET_CREDIT =
  "vacationRentalBook/SET_TRAVEL_WALLET_CREDIT";
export type SET_TRAVEL_WALLET_CREDIT = typeof SET_TRAVEL_WALLET_CREDIT;

export const SET_CREDIT_TO_APPLY = "vacationRentalBook/SET_CREDIT_TO_APPLY";
export type SET_CREDIT_TO_APPLY = typeof SET_CREDIT_TO_APPLY;

export const RESET_PAYMENT_CARD_SELECTED_ACCOUNTS =
  "vacationRentalBook/RESET_PAYMENT_CARD_SELECTED_ACCOUNTS";
export type RESET_PAYMENT_CARD_SELECTED_ACCOUNTS =
  typeof RESET_PAYMENT_CARD_SELECTED_ACCOUNTS;

export const SCHEDULE_VACATION_RENTAL_PRICE_QUOTE =
  "vacationRentalBook/SCHEDULE_VACATION_RENTAL_PRICE_QUOTE";
export type SCHEDULE_VACATION_RENTAL_PRICE_QUOTE =
  typeof SCHEDULE_VACATION_RENTAL_PRICE_QUOTE;

export const SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_SUCCESS =
  "vacationRentalBook/SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_SUCCESS";
export type SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_SUCCESS =
  typeof SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_SUCCESS;

export const SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_FAILED =
  "vacationRentalBook/SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_FAILED";
export type SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_FAILED =
  typeof SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_FAILED;

export const POLL_VACATION_RENTALS_PRICE_QUOTE =
  "vacationRentalBook/POLL_VACATION_RENTALS_PRICE_QUOTE";
export type POLL_VACATION_RENTALS_PRICE_QUOTE =
  typeof POLL_VACATION_RENTALS_PRICE_QUOTE;

export const SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_SUCCESS =
  "vacationRentalBook/SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_SUCCESS";
export type SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_SUCCESS =
  typeof SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_SUCCESS;

export const SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_FAILED =
  "vacationRentalBook/SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_FAILED";
export type SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_FAILED =
  typeof SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_FAILED;

export const SET_VACATION_RENTALS_PRICE_QUOTE =
  "vacationRentalBook/SET_VACATION_RENTALS_PRICE_QUOTE";
export type SET_VACATION_RENTALS_PRICE_QUOTE =
  typeof SET_VACATION_RENTALS_PRICE_QUOTE;

export const ADD_MAIN_GUEST = "vacationRentalBook/ADD_MAIN_GUEST";
export type ADD_MAIN_GUEST = typeof ADD_MAIN_GUEST;

export const ADD_MAIN_GUEST_SUCCESS =
  "vacationRentalBook/ADD_MAIN_GUEST_SUCCESS";
export type ADD_MAIN_GUEST_SUCCESS = typeof ADD_MAIN_GUEST_SUCCESS;

export const ADD_MAIN_GUEST_FAILED = "vacationRentalBook/ADD_MAIN_GUEST_FAILED";
export type ADD_MAIN_GUEST_FAILED = typeof ADD_MAIN_GUEST_FAILED;

export const SCHEDULE_VACATION_RENTALS_BOOK =
  "vacationRentalBook/SCHEDULE_VACATION_RENTALS_BOOK";
export type SCHEDULE_VACATION_RENTALS_BOOK =
  typeof SCHEDULE_VACATION_RENTALS_BOOK;

export const SET_SCHEDULE_VACATION_RENTALS_BOOK_SUCCESS =
  "vacationRentalBook/SET_SCHEDULE_VACATION_RENTALS_BOOK_SUCCESS";
export type SET_SCHEDULE_VACATION_RENTALS_BOOK_SUCCESS =
  typeof SET_SCHEDULE_VACATION_RENTALS_BOOK_SUCCESS;

export const SET_SCHEDULE_VACATION_RENTALS_BOOK_FAILED =
  "vacationRentalBook/SET_SCHEDULE_VACATION_RENTALS_BOOK_FAILED";
export type SET_SCHEDULE_VACATION_RENTALS_BOOK_FAILED =
  typeof SET_SCHEDULE_VACATION_RENTALS_BOOK_FAILED;

export const POLL_VACATION_RENTALS_CONFIRMATION_DETAILS =
  "vacationRentalBook/POLL_VACATION_RENTALS_CONFIRMATION_DETAILS";
export type POLL_VACATION_RENTALS_CONFIRMATION_DETAILS =
  typeof POLL_VACATION_RENTALS_CONFIRMATION_DETAILS;

export const POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS =
  "vacationRentalBook/POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS";
export type POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS =
  typeof POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS;

export const POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_FAILED =
  "vacationRentalBook/POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_FAILED";
export type POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_FAILED =
  typeof POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_FAILED;

export const POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_PENDING =
  "vacationRentalBook/POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_PENDING";
export type POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_PENDING =
  typeof POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_PENDING;

export const SET_VACATION_RENTALS_CONFIRMATION_DETAILS =
  "vacationRentalBook/SET_VACATION_RENTALS_CONFIRMATION_DETAILS";
export type SET_VACATION_RENTALS_CONFIRMATION_DETAILS =
  typeof SET_VACATION_RENTALS_CONFIRMATION_DETAILS;
