import { GuestsSelection } from "redmond";
import { actions, actionTypes } from "../actions";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../api/config";
import { IVacationRentalSearchState } from "./state";

export const DEFAULT_ADULT_COUNT = 2;
export const CORP_DEFAULT_ADULT_COUNT = 1;

const initialState: IVacationRentalSearchState = {
  location: null,
  fromDate: null,
  untilDate: null,
  adultsCount: isCorpTenant(config.TENANT)
    ? CORP_DEFAULT_ADULT_COUNT
    : DEFAULT_ADULT_COUNT,
  children: [],
  petsCount: 0,
  vacationRentalsLocation: null,
};

export function reducer(
  state: IVacationRentalSearchState = initialState,
  action: actions.VacationRentalSearchActions
): IVacationRentalSearchState {
  switch (action.type) {
    case actionTypes.SET_LOCATION:
      const { location } = action;
      return {
        ...state,
        location: location ? { ...location } : null,
      };

    case actionTypes.SET_FROM_DATE:
      return { ...state, fromDate: action.date };

    case actionTypes.SET_UNTIL_DATE:
      return {
        ...state,
        untilDate: action.date,
      };

    case actionTypes.SET_OCCUPANCY_COUNTS:
      const { adults, children, pets } = action.counts as GuestsSelection;
      return {
        ...state,
        adultsCount: adults,
        children: children,
        petsCount: pets || 0,
      };

    case actionTypes.SET_VACATION_RENTALS_LOCATION:
      const { vacationRentalsLocation } = action;

      return {
        ...state,
        vacationRentalsLocation: vacationRentalsLocation
          ? { ...vacationRentalsLocation }
          : null,
      };

    default:
      return state;
  }
}

export * from "./selectors";
