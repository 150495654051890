import React from "react";
import { LoadingPopup, B2BSpinner, PoweredByHopper } from "halifax";
import { BookingInProgressModalConnectorProps } from "./container";
import "./styles.scss";
import { useEffect } from "react";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { VIEWED_HOTEL_BOOK_PROCESSING } from "redmond";
import dayjs from "dayjs";
import { RouteComponentProps } from "react-router";

export interface IBookingInProgressModalProps
  extends BookingInProgressModalConnectorProps,
    RouteComponentProps {}

export const BookingInProgressModal = ({
  inProgress,
  priceQuoteInProgress,
  reservation,
}: IBookingInProgressModalProps) => {
  const startTime = dayjs();
  useEffect(() => {
    if (!inProgress && reservation) {
      trackEvent({
        eventName: VIEWED_HOTEL_BOOK_PROCESSING,
        properties: {
          runtime: dayjs().diff(startTime, "seconds", true),
        },
      });
    }
  }, [inProgress, reservation]);
  return (
    <LoadingPopup
      indicatorSize={"small"}
      indicator={B2BSpinner}
      open={inProgress || priceQuoteInProgress}
      popupSize={"small"}
      message={
        priceQuoteInProgress
          ? "Saving travelers and checking availability"
          : inProgress
          ? "Booking your stay"
          : "Please wait"
      }
      footer={PoweredByHopper}
    />
  );
};
