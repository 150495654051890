import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import { VacationRentalShopCallState } from "../index";
import {
  ITrackingProperties,
  ObfuscatedAddress,
  ViewedVacationRentalDetailsProperties,
} from "redmond";
import { getSelectedAccount } from "../../../rewards/reducer";
import { getAdultsCount, getChildrenCount, getFromDate, getPetsCount, getUntilDate } from "../../../search/reducer";
import dayjs from "dayjs";

export const getVacationRentalShopSelectedListing = (state: IStoreState) =>
  state.vacationRentalShop.selectedHome;

export const getVacationRentalShopSelectedListingCalendar = (
  state: IStoreState
) => state.vacationRentalShop.selectedHomeCalendar;

export const getVacationRentalShopUnavailableDates = (state: IStoreState) =>
  getVacationRentalShopSelectedListingCalendar(state)
    ?.calendarDays?.filter(
      (calendarDay) =>
        !calendarDay.isAvailable && !calendarDay.isAvailableForCheckout
    )
    .map((calendarDay) => dayjs(calendarDay.date).toDate());

export const getNotifyIfShopAndBookPriceDiffer = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalNotifyIfShopAndBookPriceDiffer;

export const getVacationRentalShopSelectedListingId = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalListingId;

export const getVacationRentalShopCallState = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalShopCallState;

export const getVacationRentalShopCallError = (state: IStoreState) =>
  state.vacationRentalShop.vacationRentalShopCallError;

export const hasVacationRentalShopFailed = createSelector(
  getVacationRentalShopCallState,
  (vrShopCallState) => {
    return vrShopCallState === VacationRentalShopCallState.Failed;
  }
);

export const getViewedVacationRentalDetailsProperties = createSelector(
  getSelectedAccount,
  getFromDate,
  getUntilDate,
  getAdultsCount,
  getChildrenCount,
  getPetsCount,
  getVacationRentalShopSelectedListing,
  (
    account,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
    listing
  ): ITrackingProperties<ViewedVacationRentalDetailsProperties> => {
    return {
      properties: {
        adults_count: adultsCount,
        account_type_selected: account?.productDisplayName || "",
        account_use_type: account?.accountUseType,
        advance: dayjs(fromDate).diff(dayjs(), "day"),
        check_in_date: fromDate?.toDateString(),
        check_out_date: untilDate?.toDateString(),
        children_count: childrenCount,
        credit_balance: 0,
        customer_account_role: account?.customerAccountRole,
        delegated_to: "",
        first_launch: false,
        has_credits: false,
        home_city: (
          listing?.listing.content.location.address as ObfuscatedAddress
        )?.city,
        home_host_name: listing?.listing.hostInfo?.hostName,
        home_id: listing?.listingId.id,
        home_state: (
          listing?.listing.content.location.address as ObfuscatedAddress
        )?.state,
        los: dayjs(untilDate).diff(fromDate, "day"),
        market_id: listing?.listing?.market?.id, // Verify this
        market_name: listing?.listing?.market?.name, // Verify this
        number_of_photos: listing?.listing.content.media.length,
        pets_count: petsCount,
        vx_statement_credit_balance: 0,
        ...listing?.listing.trackingProperties?.properties,
      },
      encryptedProperties: [
        listing?.listing.trackingProperties?.encryptedProperties ?? "",
      ]
    };
  }
);
