import React, { useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router";
import { VacationRentalShopConnectorProps } from "./container";
import { CallState } from "redmond";
import {
  AVAILABLE,
  getExperimentVariant,
  STAYS_BOOKING_EXPERIMENT,
  useExperiments,
} from "../../context/experiments";
import queryStringParser from "query-string";
import { Typography } from "@material-ui/core";
import { ConnectedShop } from "./components/Shop/container";
import "./styles.scss";

export interface IVacationRentalShopProps
  extends VacationRentalShopConnectorProps,
    RouteComponentProps {}

export const VacationRentalShop = (props: IVacationRentalShopProps) => {
  const {
    history,
    selectedListing,
    rewardsAccounts,
    setSelectedAccount,
    fetchVacationRentalShop,
  } = props;

  const expState = useExperiments();

  const staysBookingExperiment = getExperimentVariant(
    expState.experiments,
    STAYS_BOOKING_EXPERIMENT
  );

  const isStaysBookingEnabled = useMemo(
    () => staysBookingExperiment === AVAILABLE,
    [staysBookingExperiment]
  );

  const queryString = useMemo(
    () => queryStringParser.parse(history.location.search),
    [history.location.search]
  );

  useEffect(() => {
    if (
      [CallState.Success, CallState.Failed].includes(expState.callState) ||
      expState.experiments.length > 0
    ) {
      if (isStaysBookingEnabled) {
        fetchVacationRentalShop(history, {
          overrideStateByQueryParams: true,
          fetchListingCalendar: true,
        });
      }
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
  }, [expState.callState, queryString.listingId]);

  useEffect(() => {
    if (selectedListing) {
      document.title = selectedListing.listing.content?.name;
    }
  }, [selectedListing]);

  useEffect(() => {
    const { selectedAccountIndex } = queryStringParser.parse(
      history.location.search
    );

    if (selectedAccountIndex && rewardsAccounts.length > 0) {
      setSelectedAccount(
        rewardsAccounts[Number(selectedAccountIndex)]?.accountReferenceId
      );
    }
  }, [rewardsAccounts]);

  return selectedListing ? (
    <ConnectedShop />
  ) : (
    // TODO: Add loading skeleton
    <Typography>Loading...</Typography>
  );
};
