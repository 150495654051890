export const SET_SEARCHED_DATES =
  "vacationRentalAvailability/SET_SEARCHED_DATES";
export type SET_SEARCHED_DATES = typeof SET_SEARCHED_DATES;

export const SET_SEARCHED_OCCUPANCY_COUNTS =
  "vacationRentalAvailability/SET_SEARCHED_OCCUPANCY_COUNTS";
export type SET_SEARCHED_OCCUPANCY_COUNTS =
  typeof SET_SEARCHED_OCCUPANCY_COUNTS;

export const SET_OPEN_DATES_MODAL =
  "vacationRentalAvailability/SET_OPEN_DATES_MODAL";
export type SET_OPEN_DATES_MODAL = typeof SET_OPEN_DATES_MODAL;
