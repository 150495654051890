import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getUntilDate,
  getPetsCount,
} from "../../../search/reducer";
import { DesktopRewardsHeader } from "./component";
import {
  getVacationRentalShopCallState,
  getVacationRentalShopSelectedListing,
  VacationRentalShopCallState,
} from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => ({
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  listing: getVacationRentalShopSelectedListing(state),
  vacationRentalShopCallInProgress:
    getVacationRentalShopCallState(state) ===
    VacationRentalShopCallState.InProcess,
  adultsCount: getAdultsCount(state),
  petsCount: getPetsCount(state),
  children: getChildren(state),
});

const connector = connect(mapStateToProps);
type DesktopRewardsHeaderConnectorProps = ConnectedProps<typeof connector>;

export interface IDesktopRewardsHeaderProps
  extends DesktopRewardsHeaderConnectorProps,
    RouteComponentProps {
  variant?: "shop" | "customize";
  title?: string;
  subtitle?: string;
}

export const ConnectedDesktopRewardsHeader = withRouter(
  connector(DesktopRewardsHeader)
);
