import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import { getNightCount } from "../../../search/reducer";

import { getViewedVacationRentalDetailsProperties } from "../../reducer/selectors";
import { MobilePhotoGallery } from "./component";
import { getVacationRentalShopSelectedListing } from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    viewedVacationRentalDetailsProperties:
      getViewedVacationRentalDetailsProperties(state),
    listing: getVacationRentalShopSelectedListing(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePhotoGalleryConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobilePhotoGallery = withRouter(
  connector(MobilePhotoGallery)
);
