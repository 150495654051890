import { Box, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import {
  B2BButton,
  DesktopPopupModal,
  Icon,
  IconName,
  PassengerCountPicker,
  PassengerCountPickerType,
} from "halifax";
import { GuestsSelection } from "redmond";

import { OccupancySelectionConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface OccupancySelectionProps
  extends OccupancySelectionConnectorProps {
  onClickSubmit?: () => void;
}

export const OccupancySelection = (props: OccupancySelectionProps) => {
  const {
    selectedHome,
    adultsCount,
    children,
    setOccupancyCounts,
    petsCount,
    onClickSubmit,
  } = props;
  const [roomsCount, setRoomsCount] = React.useState(1);

  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    React.useState(false);

  return (
    <Box className={clsx("desktop-vr-occupancy-selection", "vacation-rentals")}>
      <B2BButton
        aria-label={`Guests ${textConstants.INPUT_LABEL(
          adultsCount,
          children
        )}`}
        className="num-travelers-input b2b"
        variant="traveler-selector"
        onClick={() => setOpenPassengerCountPicker(true)}
      >
        <Box className="num-traveler-content">
          <Icon
            aria-hidden={true}
            className="icon-start"
            name={IconName.B2BUser}
            ariaLabel=""
          />
          <Box className="text">
            {textConstants.INPUT_LABEL(adultsCount, children, petsCount, true)}
          </Box>
          <Icon
            aria-hidden={true}
            className="icon-end"
            name={IconName.B2BEditPencil}
            ariaLabel=""
          />
        </Box>
      </B2BButton>
      {openPassengerCountPicker && (
        <DesktopPopupModal
          open={openPassengerCountPicker}
          aria-labelledby="passenger-count-picker"
          className={clsx(
            "desktop-vr-occupancy-picker-popup",
            "vacation-rentals"
          )}
          contentClassName="desktop-passenger-count-picker-popup-container"
          onClose={() => setOpenPassengerCountPicker(false)}
          invisibleBackdrop={false}
          headerElement={textConstants.MODAL_TITLES.modalTitle}
        >
          <PassengerCountPicker
            titles={{
              ...textConstants.MODAL_TITLES,
              ...{
                petsTitle: "Pets",
                petsSubtitle: (
                  <>
                    <Tooltip
                      placement="bottom"
                      title={
                        <Typography
                          variant="subtitle2"
                          className="tooltip-text"
                          dangerouslySetInnerHTML={{
                            __html: textConstants.PETS_TOOLTIP,
                          }}
                        />
                      }
                      classes={{
                        popper: "pets-info-tooltip-popper",
                        tooltip: "pets-info-tooltip-text",
                      }}
                      enterTouchDelay={50}
                      leaveTouchDelay={2500}
                      tabIndex={0}
                    >
                      <div className="pets-info-tooltip-content">
                        <Typography variant="body2">
                          {textConstants.ADDITIONAL_FEE_TEXT}
                        </Typography>
                        <Icon name={IconName.InfoCircle} />
                      </div>
                    </Tooltip>
                  </>
                ),
              },
            }}
            setPassengerCounts={(counts: PassengerCountPickerType) => {
              const { adults, children, rooms, pets } =
                counts as GuestsSelection;
              setOccupancyCounts({ adults, children, pets });
              setRoomsCount(rooms);
            }}
            onClickApply={() => {
              // this would call price quote again
              setOpenPassengerCountPicker(false);
              if (onClickSubmit) onClickSubmit();
            }}
            counts={{
              adults: adultsCount,
              children: children,
              rooms: roomsCount,
              pets: petsCount,
            }}
            className="b2b"
            unlimited={!selectedHome?.listing.terms.maxOccupancy}
            maximumTravelersCount={selectedHome?.listing.terms.maxOccupancy}
            includeChildrenInMaxCount={true}
          />
        </DesktopPopupModal>
      )}
    </Box>
  );
};
