export const BASE_PATH_HOME = "/";
export const PATH_TRIPS = `${BASE_PATH_HOME}trips/`;
export const PATH_STAYS = "/stays/";
export const PATH_STAYS_AVAILABILITY = PATH_STAYS + "availability/";
export const PATH_VACATION_RENTALS = "/vacation-rentals/";
export const PATH_VACATION_RENTALS_SHOP = `${PATH_VACATION_RENTALS}shop/`;
export const PATH_VACATION_RENTALS_BOOK = PATH_VACATION_RENTALS + "book/";
export const PATH_VACATION_RENTALS_BOOK_CONFIRMATION =
  PATH_VACATION_RENTALS_BOOK + "confirm/";

export const PATH_TRAVEL_SALE = "/travelsale/";
export const PATH_HOTEL = "/hotels";
