import * as actionTypes from "./constants";

import { GuestsSelection } from "redmond";

export interface ISetSearchedDates {
  type: actionTypes.SET_SEARCHED_DATES;
  searchedFromDate: Date;
  searchedUntilDate: Date;
}

export const setSearchedDates = (
  searchedFromDate: Date,
  searchedUntilDate: Date
): ISetSearchedDates => ({
  type: actionTypes.SET_SEARCHED_DATES,
  searchedFromDate,
  searchedUntilDate,
});

export interface ISetOpenDatesModal {
  type: actionTypes.SET_OPEN_DATES_MODAL;
  openDatesModal: boolean;
}

export const setOpenDatesModal = (
  openDatesModal: boolean
): ISetOpenDatesModal => ({
  type: actionTypes.SET_OPEN_DATES_MODAL,
  openDatesModal,
});

export interface ISetSearchedOccupancyCounts {
  type: actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS;
  counts: Omit<GuestsSelection, "rooms">;
}

export const setSearchedOccupancyCounts = (
  counts: Omit<GuestsSelection, "rooms">
): ISetSearchedOccupancyCounts => ({
  type: actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS,
  counts,
});

export type VacationRentalAvailabilityActions =
  | ISetSearchedDates
  | ISetSearchedOccupancyCounts
  | ISetOpenDatesModal;
