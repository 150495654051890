import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getLocation,
  getPetsCount,
  getUntilDate,
} from "../../../search/reducer";
import { TitleSummaryCard } from "./component";
import { getVacationRentalShopSelectedListing } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    petsCount: getPetsCount(state),
    searchLocation: getLocation(state),
    selectedHome: getVacationRentalShopSelectedListing(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TitleSummaryCardConnectorProps = ConnectedProps<typeof connector> &
  RouteComponentProps<any>;

export const ConnectedTitleSummaryCard = withRouter(
  connector(TitleSummaryCard)
);
