import { Box, Divider, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { VacationRentalBookPassengerSelection } from "../VacationRentalBookPassengerSelection";
import {
  ActionButton,
  ActionLink,
  ContactInfoForm,
  IContactInfo,
  Icon,
  IconName,
  TravelerSelectStep,
  emailRegex,
  phoneRegex,
} from "halifax";
import clsx from "clsx";
import {
  CONTACT_INFO_HELPER_TEXT,
  CONTACT_INFO_SUBTITLE,
  CONTACT_INFO_TITLE_NO_STEP,
  EDIT_TRAVELER,
  EMAIL_HEADING,
  PHONE_HEADING,
  TRAVELER_HEADING,
  TRAVELER_INFO_TITLE,
  TRAVELER_NAME,
} from "./textConstants";
import { VacationRentalBookTravellerSectionConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { ITravelerStepErrors } from "redmond";
import "./styles.scss";
import { AGENT_FEE } from "..";
import { ClientContext } from "../../../../App";

export interface IVacationRentalBookTravellerSectionProps
  extends VacationRentalBookTravellerSectionConnectorProps,
    RouteComponentProps {
  disableEditTravelerInfo: boolean;
  setDisableEditTravelerInfo: any;
  travelerWorkflowStep: TravelerSelectStep;
  setTravelerWorkflowStep: any;
  showErrors: ITravelerStepErrors;
  setShowErrors: any;
  saveButtonClicked: boolean;
  contactInfo: IContactInfo | null;
  setContact: any;
  customerDetailsLoading: boolean;
  isFirstStepCompletedInCheckout: boolean;
  setSaveButtonClicked: any;
}

export const VacationRentalBookTravellerSection = (
  props: IVacationRentalBookTravellerSectionProps
) => {
  const {
    history,
    disableEditTravelerInfo,
    setDisableEditTravelerInfo,
    travelerWorkflowStep,
    setTravelerWorkflowStep,
    showErrors,
    setShowErrors,
    saveButtonClicked,
    contactInfo,
    customerDetailsLoading,
    setContact,
    isFirstStepCompletedInCheckout,
    setSaveButtonClicked,
    isTravelerStepComplete,
    email,
    phoneNumber,
    userSelectedTraveler,
    scheduleVacationRentalPriceQuote,
    setContactInfo,
  } = props;

  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;

  const VacationRentalTravelerInfo = (
    <Box className={clsx("vacation-rental-disable-edit-traveler-info")}>
      <>
        <Box className={clsx("checkout-template-description")}>
          <Typography variant="h2" className={clsx("checkout-template-title")}>
            {TRAVELER_INFO_TITLE}
          </Typography>
        </Box>
        <Divider className="vacation-rental-traveler-info-container-divider" />
        <Box className={clsx("vacation-rental-traveler-info-container")}>
          <Box
            className={clsx(
              "vacation-rental-travel-info-section",
              "vacation-rental-travel-info-text"
            )}
          >
            <Typography
              variant="body2"
              className={clsx("vacation-rental-traveler-info-heading")}
            >
              {TRAVELER_HEADING}
            </Typography>
            <Typography
              variant="body1"
              className={clsx("vacation-rental-traveler-info-value")}
            >
              {userSelectedTraveler
                ? TRAVELER_NAME(
                    userSelectedTraveler?.givenName ?? "",
                    userSelectedTraveler?.surname ?? ""
                  )
                : ""}
            </Typography>
          </Box>
          <Box
            className={clsx(
              "vacation-rental-travel-info-section",
              "vacation-rental-travel-info-text"
            )}
          >
            <Typography
              variant="body2"
              className={clsx("vacation-rental-traveler-info-heading")}
            >
              {PHONE_HEADING}
            </Typography>
            <Typography
              variant="body1"
              className={clsx("vacation-rental-traveler-info-value")}
            >
              {phoneNumber}
            </Typography>
          </Box>
          <Box
            className={clsx(
              "vacation-rental-travel-info-section",
              "vacation-rental-travel-info-text"
            )}
          >
            <Typography
              variant="body2"
              className={clsx("vacation-rental-traveler-info-heading")}
            >
              {EMAIL_HEADING}
            </Typography>
            <Typography
              variant="body1"
              className={clsx("vacation-rental-traveler-info-value")}
            >
              {email}
            </Typography>
          </Box>
          <Box
            className={clsx(
              "vacation-rental-travel-info-section",
              "vacation-rental-travel-info-cta"
            )}
          >
            <ActionLink
              className={clsx("vacation-rental-edit-traveler-cta")}
              onClick={() => {
                setDisableEditTravelerInfo(false);
              }}
              content={
                <>
                  <Typography className="edit-traveler-cta" variant="body2">
                    {EDIT_TRAVELER}
                  </Typography>
                  <Icon className="pencil-icon" name={IconName.PencilEdit} />
                </>
              }
            />
          </Box>
        </Box>
      </>
    </Box>
  );

  const VacationRentalEditTravelerInfo = (
    <Box className={clsx("vacation-rental-edit-traveler-info")}>
      <>
        <Box className={clsx("checkout-template-description")}>
          <Typography variant="h2" className={clsx("checkout-template-title")}>
            {TRAVELER_INFO_TITLE}
          </Typography>
        </Box>
        <Divider className="traveler-info-container-divider" />
      </>

      <VacationRentalBookPassengerSelection
        disabled={disableEditTravelerInfo}
        progress={travelerWorkflowStep}
        setProgress={setTravelerWorkflowStep}
        combinedStep
        showErrors={showErrors}
        setShowErrors={setShowErrors}
        saveButtonClicked={saveButtonClicked}
      />

      <Divider className="traveler-info-container-divider" />
      <ContactInfoForm
        disabled={disableEditTravelerInfo}
        title={CONTACT_INFO_TITLE_NO_STEP}
        subtitle={CONTACT_INFO_SUBTITLE}
        contactInfo={contactInfo}
        onContactInfoChange={(info: IContactInfo) => {
          if (
            info.email &&
            info.phoneNumber &&
            emailRegex.test(info.email) &&
            phoneRegex.test(info.phoneNumber) &&
            !(
              info.email == contactInfo?.email &&
              info.countryCode == contactInfo.countryCode &&
              info.phoneNumber == contactInfo.phoneNumber
            )
          ) {
            setContact(info);
            setContactInfo(info.email, info.phoneNumber);
          }
        }}
        showErrors={showErrors}
        setShowErrors={(errors) => {
          if (
            !(
              errors.email == showErrors?.email &&
              errors.travelerSelect == showErrors.travelerSelect &&
              errors.phone == showErrors.phone
            )
          ) {
            setShowErrors(errors);
          }
        }}
        loading={customerDetailsLoading}
      />
      <ActionButton
        className={clsx("traveler-select-workflow-button", "traveler-step-cta")}
        message="Save & Continue"
        onClick={() => {
          if (isFirstStepCompletedInCheckout) {
            setDisableEditTravelerInfo(true);
            setShowErrors({
              phone: false,
              email: false,
              travelerSelect: false,
            });
          } else {
            setShowErrors({
              phone: !contactInfo || !phoneRegex.test(contactInfo.phoneNumber),
              email: !contactInfo || !emailRegex.test(contactInfo.email),
              travelerSelect: !isTravelerStepComplete,
            });
            if (!saveButtonClicked) {
              setSaveButtonClicked(true);
            }
          }
          scheduleVacationRentalPriceQuote({
            history,
            emailAddress: email ?? "",
            agentFee: isAgentPortal ? AGENT_FEE : 0,
            pollQuoteOnly: true,
          });
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        defaultStyle="h4r-primary"
      />
      <Box className={clsx("contact-info-helper-container")}>
        <Typography
          variant="body2"
          className={clsx("contact-info-helper-text")}
        >
          {CONTACT_INFO_HELPER_TEXT}
        </Typography>
      </Box>
    </Box>
  );

  if (disableEditTravelerInfo) {
    return VacationRentalTravelerInfo;
  } else {
    return VacationRentalEditTravelerInfo;
  }
};
