import { put } from "redux-saga/effects";
import { WalletSummaryResponse } from "redmond";
import {
  setFetchApplicableTravelWalletItemsCallStateFailure,
  setFetchApplicableTravelWalletItemsCallStateSuccess,
  setTravelWalletCredit,
} from "../../actions/actions";
import { fetchTravelWalletSummary } from "../../../../api/v0/travel-wallet/fetchTravelWalletSummary";
import { actions } from "../../actions";

export function* fetchApplicableTravelWalletItemsSaga({}: actions.IFetchApplicableTravelWalletItems) {
  try {
    const summaryResponse: WalletSummaryResponse =
      yield fetchTravelWalletSummary();

    yield put(setTravelWalletCredit(summaryResponse.credit));
    yield put(setFetchApplicableTravelWalletItemsCallStateSuccess());
  } catch (e) {
    yield put(setFetchApplicableTravelWalletItemsCallStateFailure());
  }
}
