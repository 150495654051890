import { put, select } from "redux-saga/effects";
import { RewardsAccount } from "redmond";

import { actions } from "../../actions";
import { getRewardsAccounts } from "../../reducer";
import { fetchRewardsAccounts } from "../../../../api/v0/rewards/fetchRewardsAccounts";

export function* fetchRewardsAccountsSaga({
  refreshCache,
}: actions.IFetchRewardsAccounts) {
  try {
    const savedAccounts: RewardsAccount[] = yield select(getRewardsAccounts);
    if (refreshCache || savedAccounts.length < 1) {
      const rewardsAccounts: RewardsAccount[] = yield fetchRewardsAccounts();
      yield put(actions.setRewardsAccounts(rewardsAccounts));
    } else {
      yield put(actions.setRewardsAccounts(savedAccounts));
    }
  } catch (e) {
    yield put(actions.fetchRewardsAccountsCallStateFailed());
  }
}
