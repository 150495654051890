import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { getRewardsAccounts } from "../../../rewards/reducer";
import {
  getRewardsPaymentAccountReferenceId,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentInRewardsCurrency,
  rewardsAccountMinimumRequirementStateSelector,
  getCreditToApply,
  getOfferToApply,
  getTotalVacationRentalsCreditCardPaymentRequiredNumber,
  getVacationRentalsIsCreditCardPaymentRequired,
  getTotalVacationRentalsToPay,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { RewardsSelection } from "./component";
import { actions } from "../../actions";
import { getVacationRentalShopSelectedListing } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedHome = getVacationRentalShopSelectedListing(state);
  return {
    rewardsAccounts: getRewardsAccounts(state),
    selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
    rewardsPaymentInFiatCurrency: getRewardsPaymentInFiatCurrency(state),
    rewardsPaymentInRewardsCurrency: getRewardsPaymentInRewardsCurrency(state),
    isCreditCardPaymentRequired:
      getVacationRentalsIsCreditCardPaymentRequired(state),
    rewardsAccountMinimumRequirementState:
      rewardsAccountMinimumRequirementStateSelector(state),
    totalCreditCardPaymentRequired:
      getTotalVacationRentalsCreditCardPaymentRequiredNumber(state),
    tripTotal: getTotalVacationRentalsToPay(state),
    offerToApply: getOfferToApply(state),
    creditToApply: getCreditToApply(state),
    selectedHome: selectedHome,
  };
};

const mapDispatchToProps = {
  setSelectedRewardsAccountId: actions.setSelectedRewardsAccountReferenceId,
  setRewardsPaymentAmount: actions.setSelectedRewardsPaymentTotal,
  fetchProductToEarn: actions.fetchProductToEarn,
  setRewardsConversionFailed: actions.setRewardsConversionFailed,
  setPaymentMethod: actions.setPaymentMethod,
  setEarnValue: actions.setEarnValue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RewardsSelectionConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRewardsSelection = withRouter(
  connector(RewardsSelection)
);
