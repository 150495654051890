import axios from "axios";
import { config } from "../../config";
import { ShopResponse, VacationRentalShopRequest } from "redmond";
import { vacationRentalShopApiPrefix } from "../paths";

export const fetchVacationRentalShop = (
  body: VacationRentalShopRequest
): Promise<ShopResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(vacationRentalShopApiPrefix, body, {
        baseURL: config.baseURL,
      });

      const { value } = res.data;

      resolve(value?.listing);
    } catch (e) {
      reject(e);
    }
  });
