import { put, putResolve, select, call } from "redux-saga/effects";
import {
  ListingSearchResult,
  VacationRentalShopRequest,
  ListingId,
  VacationRentalCalendarResponse,
} from "redmond";

import Logger from "../../../utils/logger";

import { actions as searchActions } from "../../search/actions";
import { actions as availabilityActions } from "../../availability/actions";
import { actions } from "../actions";
import { fetchVacationRentalShop } from "../../../api/v0/shop/fetchVacationRentalShop";
import { IStoreState } from "../../../reducers/types";
import queryStringParser from "query-string";
import dayjs from "dayjs";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getPetsCount,
  getUntilDate,
} from "../../search/reducer";
import { IVacationRentalShopParsedQuery } from "../../../utils/queryStringHelpers";
import {
  getVacationRentalShopSelectedListing,
  getVacationRentalShopSelectedListingId,
} from "../reducer/selectors";
import { VacationRentalShopCallError } from "../reducer";
import { fetchVacationRentalCalendar } from "../../../api/v0/shop/fetchVacationRentalCalendar";

const DEFAULT_CHECKIN_DATE_OFFSET_IN_DAYS_FROM_TODAY = 1;
const DEFAULT_CHECKOUT_DATE_OFFSET_IN_DAYS_FROM_CHECKIN = 2;

export function* fetchVacationRentalShopSaga(
  fetchVacationRentalShopAction: actions.IFetchVacationRentalShop
) {
  try {
    const {
      shopRequestId,
      fromDate,
      untilDate,
      adultsCount,
      childrenCount,
      petsCount,
    }: {
      shopRequestId: ListingId;
      fromDate: string;
      untilDate: string;
      adultsCount: number;
      childrenCount: number;
      petsCount: number;
    } = yield call(
      setUpVacationRentalShopParameters,
      fetchVacationRentalShopAction
    );

    if (!shopRequestId) {
      throw new Error("Shop Request Id must be present.");
    }

    const requestBody: VacationRentalShopRequest = {
      listingId: shopRequestId,
      stayDetails: {
        dateRange: {
          from: fromDate,
          until: untilDate,
        },
        guestDetails: {
          adults: adultsCount,
          children: childrenCount || 0,
          infants: 0,
          petsIncluded: petsCount ? petsCount > 0 : false,
        },
      },
      distributionChannels: [
        {
          channelId: "cap1_general",
        },
      ],
    };
    const response: ListingSearchResult = yield fetchVacationRentalShop(
      requestBody
    );

    if (!response?.listing) {
      yield put(
        actions.setVacationRentalShopCallStateFailed(
          VacationRentalShopCallError.Unknown
        )
      );
      return;
    }

    yield putResolve(actions.selectHome(response));

    if (fetchVacationRentalShopAction.options?.fetchListingCalendar) {
      const calendarStart = dayjs().toDate();
      const calendarEnd = dayjs().add(1, "year").toDate();

      const calendar: VacationRentalCalendarResponse | undefined =
        yield fetchVacationRentalCalendar({
          listingId: requestBody.listingId,
          dateRange: {
            from: dayjs(calendarStart).format("YYYY-MM-DD"),
            until: dayjs(calendarEnd).format("YYYY-MM-DD"),
          },
        }).catch((e) => {
          console.error(e);
        });
      yield putResolve(actions.setListingCalendar(calendar));
    }
  } catch (e) {
    Logger.debug(e);
    actions.setVacationRentalShopCallStateFailed(
      VacationRentalShopCallError.Unknown
    );
  }
}

function parseQueryString(
  fetchVacationRentalShopAction: actions.IFetchVacationRentalShop,
  now: dayjs.Dayjs
) {
  const queryString = fetchVacationRentalShopAction.history.location.search;
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);

  // Note - The childrenCount query params are formatted as follows:

  // One child (11 years old) = &children=11
  // Two children (4 & 9 years old) = &children=4&children=9
  // Three children (2 & 6 & 15 years old) = &children=2&children=6&children=15

  const childrenCount = typeof(parsedQueryStringPrimitive.children) === 'string' ? 1 : parsedQueryStringPrimitive.children?.length

  const parsedQueryString: IVacationRentalShopParsedQuery = {
    listingId: parsedQueryStringPrimitive.listingId as string,
    fromDate: parsedQueryStringPrimitive.fromDate as string,
    untilDate: parsedQueryStringPrimitive.untilDate as string,
    adultsCount: Number(parsedQueryStringPrimitive.adultsCount),
    childrenCount: Number(childrenCount),
    petsCount: Number(parsedQueryStringPrimitive.petsCount),
    location: parsedQueryStringPrimitive.locationName as string,
  };
  
  const currentDate = dayjs().toDate();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const parsedQueryStringFromDate: Date =
    parsedQueryString.fromDate &&
    dayjs(parsedQueryString.fromDate).toDate() >= currentDate
      ? dayjs(parsedQueryString.fromDate).toDate()
      : now
          .add(DEFAULT_CHECKIN_DATE_OFFSET_IN_DAYS_FROM_TODAY, "days")
          .toDate();
  const parsedQueryStringUntilDate: Date =
    parsedQueryString.untilDate &&
    dayjs(parsedQueryString.untilDate).toDate() > parsedQueryStringFromDate
      ? dayjs(parsedQueryString.untilDate).toDate()
      : dayjs(parsedQueryStringFromDate)
          .add(DEFAULT_CHECKOUT_DATE_OFFSET_IN_DAYS_FROM_CHECKIN, "days")
          .toDate();

  const parsedQueryStringListingId: ListingId = {
    id: parsedQueryString.listingId,
  };

  return {
    parsedQueryString,
    parsedQueryStringListingId,
    parsedQueryStringFromDate,
    parsedQueryStringUntilDate,
    parsedQueryStringAdultsCount: parsedQueryString.adultsCount,
    parsedQueryStringChildrenCount: parsedQueryString.childrenCount,
    parsedQueryStringPetsCount: parsedQueryString.petsCount,
    parsedQueryStringLocation: parsedQueryString.location,
  };
}

function* setUpVacationRentalShopParameters(
  fetchVacationRentalShopAction: actions.IFetchVacationRentalShop
) {
  const state: IStoreState = yield select();

  let selectedAvailability: ListingSearchResult =
    yield getVacationRentalShopSelectedListing(state);
  let selectedListingId: ListingId =
    yield getVacationRentalShopSelectedListingId(state);
  let fromDate: Date = yield getFromDate(state);
  let untilDate: Date = yield getUntilDate(state);
  const adultsCount: number = yield getAdultsCount(state);
  const childrenCount: number = yield getChildrenCount(state);
  const petsCount: number = yield getPetsCount(state);

  const { history } = fetchVacationRentalShopAction;
  const now = dayjs();
  let {
    parsedQueryStringListingId,
    parsedQueryStringFromDate,
    parsedQueryStringUntilDate,
    parsedQueryStringAdultsCount,
    parsedQueryStringChildrenCount,
    parsedQueryStringPetsCount,
    parsedQueryStringLocation,
  } = parseQueryString(fetchVacationRentalShopAction, now);

  if (!fetchVacationRentalShopAction.options?.overrideStateByQueryParams) {
    if (
      selectedAvailability &&
      fromDate &&
      untilDate &&
      (parsedQueryStringListingId.id !== selectedAvailability.listingId.id ||
        parsedQueryStringFromDate !== fromDate ||
        parsedQueryStringUntilDate !== untilDate ||
        parsedQueryStringAdultsCount !== adultsCount ||
        parsedQueryStringChildrenCount !== childrenCount ||
        parsedQueryStringPetsCount !== petsCount)
    ) {
      history.replace({
        ...history.location,
        search: queryStringParser.stringify({
          listingId: selectedAvailability.listingId.id,
          fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
          untilDate: dayjs(untilDate).format("YYYY-MM-DD"),
          adultsCount,
          childrenCount,
          petsCount,
          locationName: parsedQueryStringLocation,
        }),
      });

      ({
        parsedQueryStringListingId,
        parsedQueryStringFromDate,
        parsedQueryStringUntilDate,
        parsedQueryStringAdultsCount,
        parsedQueryStringChildrenCount,
        parsedQueryStringPetsCount,
        parsedQueryStringLocation,
      } = parseQueryString(fetchVacationRentalShopAction, now));
    }
  }

  if (
    !selectedListingId ||
    !selectedAvailability ||
    !fromDate ||
    !untilDate ||
    fetchVacationRentalShopAction.options?.overrideStateByQueryParams ||
    fetchVacationRentalShopAction.options?.forceCallVRAvailability
  ) {
    fromDate = parsedQueryStringFromDate;
    untilDate = parsedQueryStringUntilDate;

    yield putResolve(searchActions.setFromDate(fromDate));
    yield putResolve(searchActions.setUntilDate(untilDate));
    yield putResolve(
      searchActions.setOccupancyCounts({
        adults: parsedQueryStringAdultsCount,
        children:
          parsedQueryStringChildrenCount && parsedQueryStringChildrenCount > 0
            ? new Array(parsedQueryStringChildrenCount).fill(17)
            : [],
        pets: parsedQueryStringPetsCount,
      })
    );

    yield putResolve(availabilityActions.setSearchedDates(fromDate, untilDate));
    yield putResolve(
      availabilityActions.setSearchedOccupancyCounts({
        adults: parsedQueryStringAdultsCount,
        children:
          parsedQueryStringChildrenCount && parsedQueryStringChildrenCount > 0
            ? new Array(parsedQueryStringChildrenCount).fill(17)
            : [],
      })
    );

    yield putResolve(actions.setSelectedListingId(parsedQueryStringListingId));
  }
  return {
    shopRequestId: parsedQueryStringListingId,
    fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
    untilDate: dayjs(untilDate).format("YYYY-MM-DD"),
    adultsCount: parsedQueryStringAdultsCount,
    childrenCount: parsedQueryStringChildrenCount,
    petsCount: parsedQueryStringPetsCount,
  };
}
