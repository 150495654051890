import React from "react";

import { DatePickerButton } from "halifax";

import { DesktopCalendarPicker } from "../../../../../search/components/VacationRentalSearchControl/components/DesktopCalendarPicker";
import { ConnectedCalendarPickerButtonProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";

export interface CalendarPickerButtonProps
  extends ConnectedCalendarPickerButtonProps {
  classes?: string[];
  hideSeparator?: boolean;
  hasMissingSearchInfoError?: boolean;
  onClickSubmit?: () => void;
}

export const CalendarPickerButton = ({
  classes = [],
  fromDate,
  hideSeparator,
  openDatesModal,
  setOpenDatesModal,
  untilDate,
  hasMissingSearchInfoError,
  onClickSubmit,
}: CalendarPickerButtonProps) => {
  const onClick = () => {
    setOpenDatesModal(true);
  };

  return (
    <DatePickerButton
      startDate={fromDate}
      endDate={untilDate}
      startLabel={textConstants.CHECK_IN}
      endLabel={textConstants.CHECK_OUT}
      classes={["hotel-inline-juncture-input", ...classes]}
      hideSeparator={hideSeparator}
      renderCalendarPopup={(props) => (
        <DesktopCalendarPicker
          {...props}
          open={openDatesModal && props?.open}
          closePopup={() => {
            setOpenDatesModal(false);
            props?.closePopup?.();
          }}
          onClickSubmit={onClickSubmit}
        />
      )}
      onClick={onClick}
      dateFormat={"ddd, MMM D"}
      hasMissingSearchInfoError={hasMissingSearchInfoError}
    />
  );
};
