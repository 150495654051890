import { Box, Dialog, Typography } from "@material-ui/core";
import {
  GoogleMap,
  Circle,
  OverlayViewF,
  OverlayView,
} from "@react-google-maps/api";
import { ActionButton, CloseButtonIcon, Icon, IconName } from "halifax";
import React, { useState } from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { ListingLocation } from "redmond";
import { ListingAddressEnum } from "redmond/apis/tysons/vacation-rentals";
import { IShopCtaVariant } from "../ShopCta/component";
import { ConnectedShopCta } from "../ShopCta/container";
interface Map {
  coordinates: google.maps.LatLng;
  listingName: string;
  location: ListingLocation;
  matchesMobile: boolean;
}

export const Map = (props: Map) => {
  const [showExpandeMobileMap, setShowExpandedMobileMap] =
    useState<boolean>(false);

  const [showExpandedDesktopMap, setShowExpandedDesktopMap] =
    useState<boolean>(false);

  const { coordinates, listingName, location, matchesMobile } = props;

  let city, state;

  if (
    location?.address?.ListingAddress === ListingAddressEnum.FullAddress ||
    location?.address?.ListingAddress === ListingAddressEnum.ObfuscatedAddress
  ) {
    city = location?.address?.city;
    state = location?.address?.state;
  }

  const expandMap = () => {
    if (matchesMobile) {
      setShowExpandedMobileMap(true);
    } else {
      setShowExpandedDesktopMap(true);
    }
  };

  const closeExpandedMap = () => {
    setShowExpandedMobileMap(false);
    setShowExpandedDesktopMap(false);
  };

  const googleMapComponent = () => {
    return (
      <GoogleMap
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{
          clickableIcons: false,
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: matchesMobile ? false : true,
        }}
        center={coordinates}
        zoom={15}
      >
        <Circle
          center={coordinates}
          options={{
            center: coordinates,
            radius: 500,
            strokeColor: "#fff",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: "rgba(2, 118, 177, 1.0)",
            fillOpacity: 0.35,
          }}
        />
        <OverlayViewF
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={coordinates}
          getPixelPositionOffset={() => ({ x: -10, y: -10 })}
        >
          <Box className="map-marker">
            <Icon name={IconName.LocationDotPin} />
          </Box>
        </OverlayViewF>
      </GoogleMap>
    );
  };

  return (
    <>
      <Box
        className="vacation-rental-mobile-map"
        style={{
          height: matchesMobile ? "125px" : "200px",
          width: "auto",
          position: "relative",
        }}
      >
        <GoogleMap
          mapContainerStyle={{ height: "100%", width: "100%" }}
          options={{
            clickableIcons: false,
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          center={coordinates}
          zoom={15}
        />
        <Box
          position="absolute"
          bottom="calc(50% - 22px)"
          left="calc(50% - 62.5px)"
        >
          <ActionButton
            buttonClassName="map-expand-button"
            onClick={() => expandMap()}
            message={"Show on map"}
          />
        </Box>
      </Box>

      <Dialog open={showExpandeMobileMap} fullScreen={true}>
        <Box display="flex" flexDirection="column">
          <Box
            height="30px"
            display="flex"
            padding="15px"
            borderBottom=".5px solid var(--grey-11)"
            alignItems="center"
          >
            <Box onClick={() => closeExpandedMap()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="calc(100% - 20px)"
            >
              <Typography>{listingName}</Typography>
              {city && state && (
                <Typography style={{ color: "#676868" }}>
                  {city}, {state}
                </Typography>
              )}
            </Box>
          </Box>

          <Box height="calc(94vh - 25px)">
            {googleMapComponent()}
            <Box position="absolute" bottom="0px" width="100%">
              <ConnectedShopCta variant={IShopCtaVariant.CTA_BUTTON_ONLY} />
            </Box>
          </Box>
        </Box>
      </Dialog>

      <Dialog open={showExpandedDesktopMap} maxWidth={false}>
        <Box display="flex" flexDirection="column" style={{ gap: "20px" }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            padding="20px 20px 0px 20px"
          >
            <CloseButtonIcon
              style={{ height: "15px", width: "15px" }}
              onClick={() => closeExpandedMap()}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h3">{listingName}</Typography>
            {city && state && (
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#676868",
                  paddingTop: "10px",
                }}
              >
                {city}, {state}
              </Typography>
            )}
          </Box>
          <Box width="700px" height="60vh" padding="0rem 5rem 5rem 5rem">
            {googleMapComponent()}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
