import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { getVacationRentalShopSelectedListing } from "../../../shop/reducer";
import { SelectedVacationRentalSummary } from "./component";
import { getFromDate, getUntilDate } from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    checkIn: getFromDate(state),
    checkOut: getUntilDate(state),
    selectedHome: getVacationRentalShopSelectedListing(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SelectedVacationRentalSummaryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedSelectedVacationRentalSummary = connector(
  SelectedVacationRentalSummary
);
