import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import { BaseAddress, Listing } from "redmond";

export interface VacationRentalTypeAndLocationProps {
  listing: Listing;
}
export const VacationRentalTypeAndLocation = ({
  listing,
}: VacationRentalTypeAndLocationProps) => {
  const homeLocation = listing.content.location.address as
    | BaseAddress
    | undefined;

  return (
    <Box display="flex" color="#141414">
      <Box paddingRight="5px">
        <Icon name={IconName.HomeIcon} className="icon-home" />
      </Box>
      <Typography
        style={{ fontSize: "12px", fontWeight: "600", color: "#141414" }}
      >
        Entire Home
      </Typography>
      {homeLocation?.city && (
        <>
          <Box paddingX="10px" color="#CECFCD">
            |
          </Box>
          <Typography style={{ fontSize: "12px", color: "#141414" }}>
            {homeLocation?.city}
          </Typography>
        </>
      )}
    </Box>
  );
};
