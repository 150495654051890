import { delay, putResolve, select } from "redux-saga/effects";
import pollPriceQuote from "../../../../api/v0/book/book-flow/pollPriceQuote";
import { IStoreState } from "../../../../reducers/types";
import { getSession } from "../../reducer";
import { HomesPriceQuote } from "redmond";
import {
  setPollPriceQuoteCallStateSuccess,
  setPollVacationRentalsPriceQuoteCallStateFailure,
  setVacationRentalsPriceQuote,
} from "../../actions/actions";
import { actions } from "../../actions";
import {
  Product,
  QuoteFailure,
  QuoteResponseEnum,
  QuoteSuccessV0,
  QuoteResponseV0,
} from "@b2bportal/purchase-api";

export function* pollVacationRentalsPriceQuoteSaga({
  pollQuoteOnly,
}: actions.IPollVacationRentalsPriceQuote) {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }

    const delayTimes = [1000];

    let pollFailed = false;
    let index = 0;
    while (!pollFailed) {
      yield delay(delayTimes[index]);
      const priceQuoteCheckedResponse: QuoteResponseV0 = yield pollPriceQuote(
        sessionToken
      );

      switch (priceQuoteCheckedResponse.QuoteResponse) {
        case QuoteResponseEnum.Failure:
          pollFailed = true;
          const failedResponse = priceQuoteCheckedResponse as QuoteFailure;
          if (failedResponse.errors.length > 0) {
            yield putResolve(
              setPollVacationRentalsPriceQuoteCallStateFailure(
                failedResponse.errors
              )
            );
            return;
          } else {
            yield putResolve(
              setPollVacationRentalsPriceQuoteCallStateFailure([])
            );
            throw new Error(
              "Vacation Rentals price quote checked response returned an error and the given error code is not handleable."
            );
          }

        case QuoteResponseEnum.Pending:
          break;
        case QuoteResponseEnum.Success:
          const priceQuoteResponse =
            priceQuoteCheckedResponse as QuoteSuccessV0;
          switch (priceQuoteResponse.quote.type) {
            case Product.Homes:
              // BE returns a HotelPriceQuoteWithAncillaries object
              // If hotels cfar is not enabled, the hotelAncillaryQuotes and combinedPricing fields will be empty
              const priceQuote = priceQuoteResponse.quote
                .value as HomesPriceQuote;

              yield putResolve(setPollPriceQuoteCallStateSuccess());
              yield putResolve(setVacationRentalsPriceQuote(priceQuote));

              if (pollQuoteOnly) {
                return;
              }
              return;
            default:
              pollFailed = true;
              yield putResolve(
                setPollVacationRentalsPriceQuoteCallStateFailure([])
              );
              throw new Error("Vacation Rentals Price Quote Failed");
          }
      }
      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    yield putResolve(setPollVacationRentalsPriceQuoteCallStateFailure([]));
  }
}
