import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  setContactInfo,
  fetchApplicableTravelWalletItems,
  resetPaymentCardSelectedAccounts,
  setPriceQuote,
  setUserSelectedPassengerIds,
  scheduleVacationRentalPriceQuote,
  scheduleVacationRentalsBook,
  addMainGuest,
} from "../../actions/actions";
import { MobileVacationRentalsBookWorkflow } from "./component";
import {
  getSelectedPaymentMethodId,
  getSession,
  getUserSelectedTravelerId,
  hasNoUserPassengersSelector,
  getConfirmationPhoneNumber,
  getConfirmationEmail,
  getPriceDifferenceAcknowledged,
  getPriceQuoteErrors,
  getConfirmationDetailsError,
  getOffers,
  getRewardsPaymentAccountReferenceId,
  getCredit,
  getVacationRentalsPriceQuote,
  getVacationRentalsIsTravelWalletPaymentOnly,
  getVacationRentalsIsBookingValid,
  getVacationRentalsPollPriceQuoteCallState,
  getVacationRentalsSchedulePriceQuoteCallState,
  getVacationRentalConfirmationDetails,
  getUserSelectedTraveler,
} from "../../reducer";
import { CallState } from "redmond";
import { fetchTravelWalletCreditHistory } from "../../../travel-wallet/actions/actions";
import { getRewardsAccountWithLargestEarnForBanner } from "../../../rewards/reducer";
import { getVacationRentalShopSelectedListing } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    priceQuote: getVacationRentalsPriceQuote(state),
    priceQuoteInProgress:
      getVacationRentalsSchedulePriceQuoteCallState(state) ===
        CallState.InProcess ||
      getVacationRentalsPollPriceQuoteCallState(state) === CallState.InProcess,
    priceQuoteErrors: getPriceQuoteErrors(state),
    currentSession: getSession(state),
    paymentMethodId: getSelectedPaymentMethodId(state),
    selectedTravelerId: getUserSelectedTravelerId(state),
    isBookingValid: getVacationRentalsIsBookingValid(state),
    hasNoUserPassengers: hasNoUserPassengersSelector(state),
    confirmationEmail: getConfirmationEmail(state),
    confirmationPhoneNumber: getConfirmationPhoneNumber(state),
    priceDifferenceAcknowledged: getPriceDifferenceAcknowledged(state),
    confirmationDetailsErrors: getConfirmationDetailsError(state),
    offers: getOffers(state),
    rewardsPaymentAccountReferenceId:
      getRewardsPaymentAccountReferenceId(state),
    isTravelWalletPaymentOnly:
      getVacationRentalsIsTravelWalletPaymentOnly(state),
    credit: getCredit(state),
    largestEarnAccount: getRewardsAccountWithLargestEarnForBanner(state),
    selectedHome: getVacationRentalShopSelectedListing(state),
    vrReservation: getVacationRentalConfirmationDetails(state),
    userSelectedTraveler: getUserSelectedTraveler(state),
  };
};

const mapDispatchToProps = {
  scheduleVacationRentalPriceQuote,
  setContactInfo,
  scheduleVacationRentalsBook,
  fetchApplicableTravelWalletItems,
  resetPaymentCardSelectedAccounts,
  setPriceQuote,
  fetchTravelWalletCreditHistory,
  setUserSelectedPassengerIds,
  addMainGuest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileVacationRentalsBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileVacationRentalsBookWorkflow = withRouter(
  connector(MobileVacationRentalsBookWorkflow)
);
