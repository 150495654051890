import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { fetchVacationRentalShop } from "../../actions/actions";

import { withRouter } from "react-router";
import { DateSelector } from "./component";
import { setFromDate, setUntilDate } from "../../../search/actions/actions";
import { getFromDate, getUntilDate } from "../../../search/reducer";
import { getVacationRentalShopUnavailableDates } from "../../reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  const unavailableDates = getVacationRentalShopUnavailableDates(state);
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    ...(unavailableDates ? { unavailableDates } : {}),
  };
};

const mapDispatchToProps = {
  fetchVacationRentalShop,
  setFromDate,
  setUntilDate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DateSelectorConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDateSelector = withRouter(connector(DateSelector));
