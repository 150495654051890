import React from "react";
import { Box, Typography } from "@material-ui/core";
import { TruncatedText } from "../../shop/components/TruncatedText/component";

export interface VacationRentalImportantInformationProps {
  importantInformation: string;
}

export const VacationRentalImportantInformation = ({
  importantInformation,
}: VacationRentalImportantInformationProps) => {
  return (
    <Box className="section">
      <Typography className="title">Important Information</Typography>
      <TruncatedText text={importantInformation} limit={200} setAsHTML={true} />
    </Box>
  );
};
