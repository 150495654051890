import React from "react";
import { RouteComponentProps } from "react-router";
import { DesktopPhotoGalleryConnectorProps } from "./container";
import { useEffect, useMemo, useState } from "react";
import {
  DesktopPopupModal,
  getImagesArray,
  HotelName,
  Icon,
  IconName,
  PhotoLayout,
} from "halifax";
import { Skeleton } from "@material-ui/lab";
// import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import clsx from "clsx";
import "./styles.scss";
import { VacationRentalTypeAndLocation } from "../../../common/VacationRentalTypeAndLocation/component";
import { Box, Button, Typography } from "@material-ui/core";
import { PolicyEnum } from "redmond";
import { ConnectedShopCta } from "../ShopCta/container";
import { IShopCtaVariant } from "../ShopCta/component";

export interface DesktopPhotoGalleryProps
  extends DesktopPhotoGalleryConnectorProps,
    RouteComponentProps {
  isSkeleton?: boolean;
}

export const DesktopPhotoGallery = (props: DesktopPhotoGalleryProps) => {
  const { listing, isSkeleton } = props;

  const [imagesArray, setImagesArray] = useState<string[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  useEffect(() => {
    getImagesArray(listing?.listing?.content?.media ?? [], setImagesArray);
  }, [listing, setImagesArray]);

  const filteredImagesArray = useMemo(
    () =>
      // The filter method is used to remove empty items in imagesArray
      imagesArray.filter((imageTuple) => !!imageTuple),
    [imagesArray]
  );

  const { cancellationPolicy } = listing?.availability?.rate ?? {};

  const renderFreeCancellation = () => {
    return (
      cancellationPolicy?.policy.Policy === PolicyEnum.FullyRefundable && (
        <Box
          display="flex"
          justifyContent="start"
          paddingTop="10px"
          paddingBottom="10px"
        >
          <Icon name={IconName.FreeCancel} />
          <Typography
            style={{
              fontSize: "12px",
              fontWeight: "600",
              color: "var(--green-5)",
              marginLeft: "5px",
            }}
          >
            Free cancellation
          </Typography>
        </Box>
      )
    );
  };

  return (
    <div className="desktop-vr-photo-modal">
      {isSkeleton && (
        <Skeleton className="desktop-vr-photo-modal-main-image-container skeleton" />
      )}
      {!isSkeleton && (
        <div
          className="desktop-vr-photo-modal-main-image-container"
          onClick={() => {
            setOpenModal(!openModal);
          }}
        >
          <div className="desktop-vr-photo-modal-main-image-section-left">
            <img
              className="main-image-left"
              src={filteredImagesArray[0]}
              alt="pc-1"
            />
          </div>
          <div className="desktop-vr-photo-modal-main-image-section-right">
            <div className="main-image-right top-image">
              <img src={filteredImagesArray[1]} alt="pc-2" />
            </div>
            <div className="main-image-right bottom-image">
              <img src={filteredImagesArray[2]} alt="pc-3" />
            </div>
          </div>
          <Button
            className="show-all-photos-button"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <Icon name={IconName.ShowAllImagesIcon} />
            <span className="show-all-photos-button-label">
              See all photos ({filteredImagesArray.length})
            </span>
          </Button>
        </div>
      )}
      <DesktopPopupModal
        className={clsx("desktop-vr-photo-modal-popup", "b2b")}
        open={openModal}
        invisibleBackdrop={false}
        onClose={(event: React.MouseEvent) => {
          event.stopPropagation();
          setOpenModal(false);
        }}
      >
        <div
          id="desktop-vr-photo-modal-container"
          className="desktop-vr-photo-modal-container"
        >
          <div id="photo-gallery" className="photo-gallery">
            <PhotoLayout
              imgUrls={filteredImagesArray}
              scrollableTarget="photo-gallery"
              className="desktop-vr-photo-layout"
            />
          </div>
          <div className="photo-gallery-pc-info">
            {listing?.listing && (
              <VacationRentalTypeAndLocation listing={listing.listing} />
            )}
            <HotelName
              name={listing?.listing?.content?.name}
              isSkeleton={isSkeleton}
            />
            {!!listing && (
              <Box marginTop="15px">
                <ConnectedShopCta variant={IShopCtaVariant.PRICING_ONLY} />

                {renderFreeCancellation()}
              </Box>
            )}
            <ConnectedShopCta variant={IShopCtaVariant.CTA_BUTTON_ONLY} />
          </div>
        </div>
      </DesktopPopupModal>
    </div>
  );
};
