import { combineReducers } from "@reduxjs/toolkit";
import { reducer as vacationRentalAvailability } from "../modules/availability/reducer";
import { reducer as vacationRentalSearch } from "../modules/search/reducer";
import { reducer as vacationRentalShop } from "../modules/shop/reducer";
import { reducer as vacationRentalRewards } from "../modules/rewards/reducer";
import { reducer as vacationRentalBook } from "../modules/book/reducer";
import { reducer as vacationRentalTravelWallet } from "../modules/travel-wallet/reducer";

export const rootReducer = combineReducers({
  vacationRentalAvailability,
  vacationRentalSearch,
  vacationRentalRewards,
  vacationRentalShop,
  vacationRentalBook,
  vacationRentalTravelWallet,
});
