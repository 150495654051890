import React from "react";
import { Box, Typography } from "@material-ui/core";
import { MobileShopHeaderConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { ActionLink } from "halifax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import "./styles.scss";
import { goToAvailability } from "../../utils/queryStringHelpers";
import { IMobileDateSelectorVariant } from "../MobileDateSelector/component";
import { ConnectedMobileDateSelector } from "../MobileDateSelector/container";

export interface IMobileShopHeaderProps
  extends MobileShopHeaderConnectorProps,
    RouteComponentProps {}

export const MobileShopHeader = (props: IMobileShopHeaderProps) => {
  const {
    selectedListing,
    fromDate,
    untilDate,
    numAdults,
    numChildren,
    numPets,
    history,
  } = props;

  const title = selectedListing?.listing?.content?.name || "Stay";

  const returnToAvailability = () => {
    goToAvailability({
      history,
      listing: selectedListing?.listing,
      fromDate,
      untilDate,
      adultsCount: numAdults,
      childrenCount: numChildren.length,
      petsCount: numPets,
    });
  };

  return (
    <Box
      className="mobile-shop-header"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      borderBottom=".5px solid var(--grey-11)"
      height="55px"
      style={{ backgroundColor: "white" }}
      paddingX="18px"
    >
      <ActionLink
        onClick={returnToAvailability}
        content={<FontAwesomeIcon icon={faChevronLeft} />}
      />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1">{title}</Typography>
        <Typography
          style={{
            fontSize: "12px",
            fontWeight: "400",
            color: "#505050",
          }}
        >
          {fromDate &&
            untilDate &&
            `${dayjs(fromDate).format("MMM DD")} - ${dayjs(untilDate).format(
              "MMM DD"
            )}, `}
          {numAdults + numChildren.length > 1
            ? `${numAdults + numChildren.length} Travelers`
            : `${numAdults + numChildren.length} Traveler`}
        </Typography>
      </Box>

      <ConnectedMobileDateSelector variant={IMobileDateSelectorVariant.ICON} />
    </Box>
  );
};
