import { actions, actionTypes } from "../actions";
import {
  CallState,
  CreditCard,
  FiatPrice,
  IPerson,
  Invalid,
  Payment,
  PaymentSessionToken,
  PaymentVerifyResultEnum,
  PersonId,
  PriceQuote,
  Reservation,
  RewardsPrice,
  TravelWalletOffer,
  TravelWalletCredit,
  HotelPricing,
  HomesPriceQuote,
  HomesReservation,
  HotelPriceQuoteScheduleRequestV2,
} from "redmond";
import { PurchaseError } from "@b2bportal/purchase-api";

const DO_NOT_APPLY_REWARDS_KEY = "do-not-apply-rewards";

export interface IVacationRentalBookState {
  userPassengers: IPerson[];
  userPassengerCallState: CallState;
  userSelectedTravelerId?: PersonId;
  paymentMethods: Payment[];
  paymentMethod?: CreditCard;
  selectedPaymentMethodId?: string;
  verifyPaymentMethodResult?: PaymentVerifyResultEnum;
  listPaymentMethodCallState: CallState;
  verifyPaymentMethodCallState: CallState;
  fetchPaymentMethodCallState: CallState;
  deletePaymentMethodCallState: CallState;
  session?: PaymentSessionToken;
  pollPriceQuoteCallState: CallState;
  schedulePriceQuoteCallState: CallState;
  schedulePriceQuoteError: Invalid | null;
  scheduleBookCallState: CallState;
  scheduleBookError: Invalid | null;
  priceQuote: PriceQuote | null;
  pricingWithAncillaries: HotelPricing | null;
  priceQuoteErrors: PurchaseError[];
  confirmationDetails: Reservation | null;
  confirmationDetailsCallState: CallState;
  confirmationDetailsErrors: PurchaseError[];
  confirmationEmailAddress?: string;
  confirmationPhoneNumber?: string;
  priceDifferenceAcknowledged: boolean;
  offerToApply?: TravelWalletOffer;
  offers?: TravelWalletOffer[];
  fetchApplicableTravelWalletItemsCallState: CallState;
  bestOfferOverall?: TravelWalletOffer;
  credit?: TravelWalletCredit;
  creditToApply?: TravelWalletCredit;
  priceQuoteRequest: HotelPriceQuoteScheduleRequestV2;

  // Payment Details
  paymentMethodRewardsAccountId?: string;
  rewardsAccountReferenceId?: string | null;
  rewardsPaymentTotal: RewardsPrice | null;
  rewardsPaymentInFiatCurrency: FiatPrice | null;
  productEarnValue: number | null;
  rewardsConversionFailed: boolean;

  // Vacation Rentals
  scheduleVacationRentalsPriceQuoteCallState: CallState;
  scheduleVacationRentalsPriceQuoteError: Invalid | null;
  pollVacationRentalsPriceQuoteCallState: CallState;
  vacationRentalsPriceQuoteErrors: PurchaseError[];
  vacationRentalsPriceQuote: HomesPriceQuote | null;
  vacationRentalsConfirmationDetails: HomesReservation | null;
  scheduleVacationRentalsBookCallState: CallState;
  scheduleVacationRentalsBookError: Invalid | null;
  vacationRentalsConfirmationDetailsErrors: PurchaseError[];
  pollVacationRentalsConfirmationDetailsCallState: CallState;
  addMainGuestCallState: CallState;

  // Approvals V2
  approvalRequestReason?: string;
}

const initialState: IVacationRentalBookState = {
  userPassengers: [],
  userPassengerCallState: CallState.NotCalled,
  userSelectedTravelerId: undefined,
  confirmationEmailAddress: undefined,
  paymentMethods: [],
  paymentMethod: undefined,
  selectedPaymentMethodId: undefined,
  verifyPaymentMethodResult: undefined,
  listPaymentMethodCallState: CallState.NotCalled,
  fetchPaymentMethodCallState: CallState.NotCalled,
  verifyPaymentMethodCallState: CallState.NotCalled,
  deletePaymentMethodCallState: CallState.NotCalled,
  session: undefined,
  pollPriceQuoteCallState: CallState.NotCalled,
  schedulePriceQuoteCallState: CallState.NotCalled,
  schedulePriceQuoteError: null,
  scheduleBookError: null,
  scheduleBookCallState: CallState.NotCalled,
  priceQuote: null,
  pricingWithAncillaries: null,
  priceQuoteErrors: [],
  confirmationDetails: null,
  confirmationDetailsCallState: CallState.NotCalled,
  confirmationDetailsErrors: [],
  rewardsAccountReferenceId: undefined,
  rewardsPaymentTotal: null,
  rewardsPaymentInFiatCurrency: null,
  productEarnValue: null,
  priceDifferenceAcknowledged: false,
  rewardsConversionFailed: false,
  offerToApply: undefined,
  offers: [],
  fetchApplicableTravelWalletItemsCallState: CallState.NotCalled,
  bestOfferOverall: undefined,
  scheduleVacationRentalsPriceQuoteCallState: CallState.NotCalled,
  scheduleVacationRentalsPriceQuoteError: null,
  pollVacationRentalsPriceQuoteCallState: CallState.NotCalled,
  vacationRentalsPriceQuoteErrors: [],
  vacationRentalsPriceQuote: null,
  scheduleVacationRentalsBookCallState: CallState.NotCalled,
  scheduleVacationRentalsBookError: null,
  vacationRentalsConfirmationDetails: null,
  vacationRentalsConfirmationDetailsErrors: [],
  pollVacationRentalsConfirmationDetailsCallState: CallState.NotCalled,
  addMainGuestCallState: CallState.NotCalled,
  priceQuoteRequest: {} as HotelPriceQuoteScheduleRequestV2,
  approvalRequestReason: undefined,
};

export function reducer(
  state: IVacationRentalBookState = initialState,
  action: actions.VacationRentalBookActions
): IVacationRentalBookState {
  switch (action.type) {
    case actionTypes.FETCH_USER_PASSENGERS:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.UPDATE_USER_PASSENGER:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.DELETE_USER_PASSENGER:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.SET_USER_PASSENGERS:
      return {
        ...state,
        userPassengerCallState: CallState.Success,
        userPassengers: [...action.userPassengers],
      };

    case actionTypes.SET_USER_SELECTED_PASSENGER_IDS:
      return {
        ...state,
        // Note: we only want to select a single traveler here.
        // We are also accounting for this in the halifax traveler workflow with the prop singleTravelerWorkflow
        userSelectedTravelerId: action.userSelectedPassengerIds[0],
      };

    case actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED:
      return {
        ...state,
        userPassengerCallState: CallState.Failed,
      };

    case actionTypes.FETCH_PAYMENT_METHOD:
      return {
        ...state,
        fetchPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod,
        selectedPaymentMethodId: action.paymentMethod?.id,
        fetchPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.SET_SELECTED_PAYMENT_METHOD_ID:
      const selectedPaymentMethod = state.paymentMethods.find(
        (method) => method.id === action.paymentMethodId
      );

      return {
        ...state,
        paymentMethod: selectedPaymentMethod,
        paymentMethodRewardsAccountId: action.accountId,
        selectedPaymentMethodId: action.paymentMethodId,
      };
    case actionTypes.RESET_PAYMENT_CARD_SELECTED_ACCOUNTS:
      return {
        ...state,
        paymentMethod: initialState.paymentMethod,
        paymentMethodRewardsAccountId:
          initialState.paymentMethodRewardsAccountId,
        rewardsAccountReferenceId: initialState.rewardsAccountReferenceId,
        selectedPaymentMethodId: initialState.selectedPaymentMethodId,
      };
    case actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        fetchPaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.LIST_PAYMENT_METHODS:
      return {
        ...state,
        listPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods,
        listPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED:
      return {
        ...state,
        listPaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.DELETE_PAYMENT_METHOD:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.Success,
      };

    case actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD:
      return {
        ...state,
        verifyPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS:
      return {
        ...state,
        verifyPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        verifyPaymentMethodResult: action.result,
        verifyPaymentMethodCallState: CallState.Failed,
      };

    // This action is called when we need to remove Failed state; for example,
    // when `userPassengerCallState === CallState.Failed` is used as a condition for opening
    // a failure popup, we might want to reset the callState
    case actionTypes.ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE:
      return {
        ...state,
        userPassengerCallState:
          state.userPassengerCallState === CallState.Failed
            ? CallState.NotCalled
            : state.userPassengerCallState,
      };

    case actionTypes.SET_SESSION:
      return {
        ...state,
        session: action.token,
      };

    case actionTypes.CLEAR_SESSION:
      return {
        ...state,
        session: undefined,
      };

    case actionTypes.ACKNOWLEDGE_PRICE_DIFFERENCE:
      return {
        ...state,
        priceDifferenceAcknowledged: true,
      };

    case actionTypes.SCHEDULE_PRICE_QUOTE:
      return {
        ...state,
        schedulePriceQuoteCallState: CallState.InProcess,
        priceDifferenceAcknowledged: false,
      };

    case actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS:
      return {
        ...state,
        schedulePriceQuoteError: null,
        schedulePriceQuoteCallState: CallState.Success,
      };

    case actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED:
      return {
        ...state,
        schedulePriceQuoteCallState: CallState.Failed,
        schedulePriceQuoteError: action.invalid,
      };

    case actionTypes.POLL_PRICE_QUOTE:
      return {
        ...state,
        pollPriceQuoteCallState: CallState.InProcess,
      };

    case actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS:
      return {
        ...state,
        pollPriceQuoteCallState: CallState.Success,
      };

    case actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED:
      return {
        ...state,
        pollPriceQuoteCallState: CallState.Failed,
        priceQuoteErrors: action.errors,
      };

    case actionTypes.SET_PRICE_QUOTE:
      return {
        ...state,
        priceQuoteErrors: [],
        priceQuote: action.priceQuote,
        pricingWithAncillaries: action.pricingWithAncillaries,
        pollPriceQuoteCallState: CallState.Success,
        selectedPaymentMethodId: undefined,
        rewardsAccountReferenceId: undefined,
        rewardsPaymentInFiatCurrency: null,
        rewardsPaymentTotal: null,
      };

    case actionTypes.SCHEDULE_BOOK:
      return {
        ...state,
        scheduleBookCallState: CallState.InProcess,
        approvalRequestReason: action.approvalRequestReason,
      };

    case actionTypes.SET_SCHEDULE_BOOK_SUCCESS:
      return {
        ...state,
        scheduleBookError: null,
        scheduleBookCallState: CallState.Success,
      };

    case actionTypes.SET_SCHEDULE_BOOK_FAILED:
      return {
        ...state,
        scheduleBookError: action.invalid,
        scheduleBookCallState: CallState.Failed,
      };

    case actionTypes.POLL_CONFIRMATION_DETAILS:
      return {
        ...state,
        confirmationDetailsCallState: CallState.InProcess,
      };

    case actionTypes.POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS:
      return {
        ...state,
        confirmationDetailsErrors: [],
        confirmationDetailsCallState: CallState.Success,
      };

    case actionTypes.POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        confirmationDetailsCallState: CallState.Failed,
        confirmationDetailsErrors: action.errors,
      };

    case actionTypes.SET_CONFIRMATION_DETAILS:
      return {
        ...state,
        confirmationDetails: action.confirmationDetails,
        confirmationDetailsCallState: CallState.Success,
      };

    case actionTypes.REDO_SEARCH:
      return {
        ...initialState,
      };

    case actionTypes.SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID:
      return {
        ...state,
        rewardsAccountReferenceId: action.rewardsAccountReferenceId,
      };

    case actionTypes.SET_SELECTED_REWARDS_PAYMENT_TOTAL:
      if (
        action.accountReferenceId === DO_NOT_APPLY_REWARDS_KEY ||
        !action.rewardsPaymentTotal ||
        !action.rewardsPaymentInFiatCurrency
      ) {
        return {
          ...state,
          rewardsPaymentInFiatCurrency: null,
          rewardsPaymentTotal: null,
        };
      }

      const tripTotal = action.tripTotal;

      // This occurs when fetching rewards fails during search, don't allow the user to accidentally use all their rewards
      if (tripTotal && !tripTotal.rewards[action.accountReferenceId]) {
        return {
          ...state,
          rewardsAccountReferenceId: DO_NOT_APPLY_REWARDS_KEY,
          rewardsPaymentInFiatCurrency: null,
          rewardsPaymentTotal: null,
        };
      }

      if (
        tripTotal &&
        tripTotal.rewards[action.accountReferenceId].value <
          action.rewardsPaymentTotal.value
      ) {
        return {
          ...state,
          rewardsPaymentInFiatCurrency: tripTotal.fiat,
          rewardsPaymentTotal: tripTotal.rewards[action.accountReferenceId],
        };
      }

      return {
        ...state,
        rewardsPaymentInFiatCurrency: action.rewardsPaymentInFiatCurrency,
        rewardsPaymentTotal: action.rewardsPaymentTotal,
      };

    case actionTypes.SET_CONTACT_INFO:
      return {
        ...state,
        confirmationEmailAddress: action.email,
        confirmationPhoneNumber: action.phoneNumber,
      };

    case actionTypes.SET_PRODUCT_EARN_VALUE:
      return {
        ...state,
        productEarnValue: action.productEarnValue,
      };

    case actionTypes.RESET_BOOK_ERRORS:
      return {
        ...state,
        pollPriceQuoteCallState: CallState.NotCalled,
        schedulePriceQuoteCallState: CallState.NotCalled,
        confirmationDetailsCallState: CallState.NotCalled,
        scheduleBookCallState: CallState.NotCalled,
        verifyPaymentMethodCallState: CallState.NotCalled,
        verifyPaymentMethodResult: undefined,
        confirmationDetailsErrors: [],
        priceQuoteErrors: [],
        schedulePriceQuoteError: null,
        scheduleBookError: null,
        rewardsConversionFailed: false,
        vacationRentalsConfirmationDetailsErrors: [],
        vacationRentalsPriceQuoteErrors: [],
        pollVacationRentalsPriceQuoteCallState: CallState.NotCalled,
        scheduleVacationRentalsPriceQuoteCallState: CallState.NotCalled,
        scheduleVacationRentalsBookCallState: CallState.NotCalled,
        pollVacationRentalsConfirmationDetailsCallState: CallState.NotCalled,
      };

    case actionTypes.REWARDS_CONVERSION_FAILED:
      return {
        ...state,
        rewardsConversionFailed: true,
      };
    case actionTypes.SET_OFFER_TO_APPLY:
      return {
        ...state,
        offerToApply: action.offer,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.InProcess,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.Success,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.Failed,
      };
    case actionTypes.SET_TRAVEL_WALLET_OFFERS:
      return {
        ...state,
        offers: action.offers,
      };
    case actionTypes.SET_BEST_OFFER_OVERALL:
      return {
        ...state,
        bestOfferOverall: action.offer,
      };
    case actionTypes.SET_CREDIT_TO_APPLY:
      return {
        ...state,
        creditToApply: action.credit,
      };
    case actionTypes.SET_TRAVEL_WALLET_CREDIT:
      return {
        ...state,
        credit: action.credit,
      };

    case actionTypes.SCHEDULE_VACATION_RENTAL_PRICE_QUOTE:
      return {
        ...state,
        scheduleVacationRentalsPriceQuoteCallState: CallState.InProcess,
        priceDifferenceAcknowledged: false,
      };

    case actionTypes.SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_SUCCESS:
      return {
        ...state,
        scheduleVacationRentalsPriceQuoteError: null,
        scheduleVacationRentalsPriceQuoteCallState: CallState.Success,
      };

    case actionTypes.SCHEDULE_VACATION_RENTAL_PRICE_QUOTE_CALL_STATE_FAILED:
      return {
        ...state,
        scheduleVacationRentalsPriceQuoteCallState: CallState.Failed,
        schedulePriceQuoteError: action.invalid,
      };
    case actionTypes.POLL_VACATION_RENTALS_PRICE_QUOTE:
      return {
        ...state,
        pollVacationRentalsPriceQuoteCallState: CallState.InProcess,
      };

    case actionTypes.SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_SUCCESS:
      return {
        ...state,
        pollVacationRentalsPriceQuoteCallState: CallState.Success,
      };

    case actionTypes.SET_POLL_VACATION_RENTALS_PRICE_QUOTE_CALL_STATE_FAILED:
      return {
        ...state,
        pollVacationRentalsPriceQuoteCallState: CallState.Failed,
        vacationRentalsPriceQuoteErrors: action.errors,
      };

    case actionTypes.SET_VACATION_RENTALS_PRICE_QUOTE:
      return {
        ...state,
        vacationRentalsPriceQuoteErrors: [],
        vacationRentalsPriceQuote: action.priceQuote,
        pollVacationRentalsPriceQuoteCallState: CallState.Success,
        selectedPaymentMethodId: undefined,
        rewardsAccountReferenceId: undefined,
        rewardsPaymentInFiatCurrency: null,
        rewardsPaymentTotal: null,
      };
    case actionTypes.SCHEDULE_VACATION_RENTALS_BOOK:
      return {
        ...state,
        scheduleVacationRentalsBookCallState: CallState.InProcess,
      };
    case actionTypes.SET_SCHEDULE_VACATION_RENTALS_BOOK_SUCCESS:
      return {
        ...state,
        scheduleVacationRentalsBookError: null,
        scheduleVacationRentalsBookCallState: CallState.Success,
      };
    case actionTypes.SET_SCHEDULE_VACATION_RENTALS_BOOK_FAILED:
      return {
        ...state,
        scheduleVacationRentalsBookError: action.invalid,
        scheduleVacationRentalsBookCallState: CallState.Failed,
      };
    case actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS:
      return {
        ...state,
        pollVacationRentalsConfirmationDetailsCallState: CallState.InProcess,
      };
    case actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS:
      return {
        ...state,
        vacationRentalsConfirmationDetailsErrors: [],
        pollVacationRentalsConfirmationDetailsCallState: CallState.Success,
      };
    case actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        pollVacationRentalsConfirmationDetailsCallState: CallState.Failed,
        vacationRentalsConfirmationDetailsErrors: action.errors,
      };
    case actionTypes.SET_VACATION_RENTALS_CONFIRMATION_DETAILS:
      return {
        ...state,
        vacationRentalsConfirmationDetails: action.confirmationDetails,
        pollVacationRentalsConfirmationDetailsCallState: CallState.Success,
      };
    case actionTypes.ADD_MAIN_GUEST:
      return {
        ...state,
        addMainGuestCallState: CallState.InProcess,
      };
    case actionTypes.ADD_MAIN_GUEST_FAILED:
      return {
        ...state,
        addMainGuestCallState: CallState.Failed,
      };
    case actionTypes.ADD_MAIN_GUEST_SUCCESS:
      return {
        ...state,
        addMainGuestCallState: CallState.Success,
      };
    default:
      return state;
  }
}

export * from "./selectors";
