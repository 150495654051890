import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingInProgressModal } from "./component";
import {
  bookingVRInProgressSelector,
  getConfirmationDetails,
  getPollVacationRentalsPriceQuoteCallState,
  getScheduleVacationRentalsPriceQuoteCallState,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { CallState } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  inProgress: bookingVRInProgressSelector(state),
  priceQuoteInProgress:
    getPollVacationRentalsPriceQuoteCallState(state) === CallState.InProcess ||
    getScheduleVacationRentalsPriceQuoteCallState(state) ===
      CallState.InProcess,
  reservation: getConfirmationDetails(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingInProgressModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingInProgressModal = withRouter(
  connector(BookingInProgressModal)
);
