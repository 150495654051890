// TODO: relocate client specific language; see https://hopper-jira.atlassian.net/wiki/spaces/ENGWEB/pages/1959952414/Discussion+on+practical+ways+to+prevent+adding+Cap+One+specific+language+into+Pawtucket
import { cardsToShowLastFourOnRewardsBanner } from "redmond";

export const VACATION_RENTALS_EARN_BANNER_TEXT = ({
  firstName,
  hotelsEarnRate,
  homesEarnRate,
  productId,
  lastFour,
  currency,
}: {
  firstName: string;
  hotelsEarnRate: string | number;
  homesEarnRate: string | number;
  productId: string;
  lastFour: string;
  currency: string;
}) => {
  let earnString = "";
  const multiplier = currency.includes("cash") ? "%" : "X";

  if (hotelsEarnRate && homesEarnRate) {
    if (hotelsEarnRate === homesEarnRate) {
      earnString = `Hi ${firstName}, earn ${hotelsEarnRate}${multiplier} ${currency} on stays when you book with your ${productId} account`;
    } else {
      earnString = `Hi ${firstName}, earn ${hotelsEarnRate}${multiplier} ${currency} on hotels and ${homesEarnRate}${multiplier} ${currency} on vacation rentals when you book with your ${productId} account`;
    }

    earnString += cardsToShowLastFourOnRewardsBanner.includes(
      productId.toLowerCase()
    )
      ? ` ending in ${lastFour}.`
      : `.`;
  }

  return earnString;
};
