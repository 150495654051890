import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  fetchApplicableTravelWalletItems,
  schedulePriceQuote,
  setPriceQuote,
} from "./actions/actions";

import { IStoreState } from "../../reducers/types";
import { VacationRentalBook } from "./component";
import { getUserPassengerCallState, getUserPassengers } from "./reducer";
import {
  getVacationRentalShopSelectedListing,
  getViewedVacationRentalDetailsProperties,
} from "../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedHome: getVacationRentalShopSelectedListing(state),
    travelers: getUserPassengers(state),
    userPassengerCallState: getUserPassengerCallState(state),
    viewedVacationRentalDetailsProperties:
      getViewedVacationRentalDetailsProperties(state),
  };
};

const mapDispatchToProps = {
  schedulePriceQuote,
  setPriceQuote,
  fetchApplicableTravelWalletItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VacationRentalBookBookConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedVacationRentalBookBook = withRouter(
  connector(VacationRentalBook)
);
