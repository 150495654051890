// Getters
export const FETCH_REWARDS_ACCOUNTS =
  "vacationRentalRewards/FETCH_REWARDS_ACCOUNTS";
export type FETCH_REWARDS_ACCOUNTS = typeof FETCH_REWARDS_ACCOUNTS;

// Setters
export const SET_REWARDS_ACCOUNTS =
  "vacationRentalRewards/SET_REWARDS_ACCOUNTS";
export type SET_REWARDS_ACCOUNTS = typeof SET_REWARDS_ACCOUNTS;

export const SET_SELECTED_ACCOUNT_REFERENCE_ID =
  "vacationRentalRewards/SET_SELECTED_ACCOUNT_REFERENCE_ID";
export type SET_SELECTED_ACCOUNT_REFERENCE_ID =
  typeof SET_SELECTED_ACCOUNT_REFERENCE_ID;

export const SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED =
  "vacationRentalRewards/SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED";
export type SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED =
  typeof SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED;

export const SET_IS_FIRST_LAUNCH = "vacationRentalRewards/SET_IS_FIRST_LAUNCH";
export type SET_IS_FIRST_LAUNCH = typeof SET_IS_FIRST_LAUNCH;

export const SET_AGENT_EMAIL = "vacationRentalRewards/SET_AGENT_EMAIL";
export type SET_AGENT_EMAIL = typeof SET_AGENT_EMAIL;

// Corporate Travel
export const SET_AUTO_APPROVAL = "vacationRentalRewards/SET_AUTO_APPROVAL";
export type SET_AUTO_APPROVAL = typeof SET_AUTO_APPROVAL;
