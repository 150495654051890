import { delay, putResolve, select } from "redux-saga/effects";
import pollFinalized from "../../../../api/v0/book/book-flow/pollFinalized";
import { IStoreState } from "../../../../reducers/types";
import {
  getCompleteBuyVacationRentalProperties,
  getSession,
} from "../../reducer";
import {
  setPollVacationRentalsConfirmationDetailsCallStateFailure,
  setPollVacationRentalsConfirmationDetailsCallStateSuccess,
  setVacationRentalsConfirmationDetails,
} from "../../actions/actions";
import {
  FulfillFailure,
  FulfillResponseEnum,
  FulfillResponseV0,
  FulfillSuccessV0,
  Product,
  ProductError,
  PaymentError,
  PurchaseError,
  PurchaseErrorEnum,
  ErrorCode,
} from "@b2bportal/purchase-api";
import {
  COMPLETE_BUY_VR,
  HomesReservation,
} from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

const toErrorString = (error: PurchaseError): string => {
  switch (error.Error) {
    case PurchaseErrorEnum.ErrorCode:
      return (error as ErrorCode).code;
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      return JSON.stringify(paymentError.value.value);
    case PurchaseErrorEnum.ProductError:
      const productError = error as ProductError;
      return JSON.stringify(productError.value.value);
    default:
      return PurchaseErrorEnum[error.Error];
  }
};

export function* pollVacationRentalsConfirmationDetailsSaga() {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    const completeBuyVacationRentalProperties =
      getCompleteBuyVacationRentalProperties(state);
    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }

    const delayTimes = [1000];
    let pollFailed = false;
    let index = 0;

    while (!pollFailed) {
      yield delay(delayTimes[index]);
      const finalizedCheckedResponse: FulfillResponseV0 = yield pollFinalized(
        sessionToken
      ).catch((e) => {
        // [CMKT-1150] do not error out on network errors, instead retry
        if (e.response.status !== 200) {
          return Promise.resolve({
            data: { result: FulfillResponseEnum.Pending },
            status: 200,
            statusText: "Gateway timeout",
            headers: [],
            config: {},
          });
        }
        return Promise.reject(e);
      });

      switch (finalizedCheckedResponse.FulfillResponse) {
        case FulfillResponseEnum.Failure:
          pollFailed = true;
          const failedResponse = finalizedCheckedResponse as FulfillFailure;
          trackEvent({
            eventName: COMPLETE_BUY_VR,
            properties: {
              ...completeBuyVacationRentalProperties.properties,
              success: false,
              failure_reason:
                failedResponse.errors.length > 0
                  ? failedResponse.errors
                      .map((error) => toErrorString(error))
                      .join(" - ")
                  : "Poll Vacation Rentals Confirmation Details response returned an error and the given error code is not handleable.",
            },
            encryptedProperties: [
              ...completeBuyVacationRentalProperties.encryptedProperties,
            ],
          });
          if (failedResponse.errors.length > 0) {
            yield putResolve(
              setPollVacationRentalsConfirmationDetailsCallStateFailure(
                failedResponse.errors
              )
            );
            return;
          } else {
            yield putResolve(
              setPollVacationRentalsConfirmationDetailsCallStateFailure([])
            );
            throw new Error(
              "Poll Vacation Rentals Confirmation Details response returned an error and the given error code is not handleable."
            );
          }

        case FulfillResponseEnum.Pending:
          break;
        case FulfillResponseEnum.Success:
          const finalizedResponse =
            finalizedCheckedResponse as FulfillSuccessV0;
          switch (finalizedResponse.fulfillment.type) {
            case Product.Homes:
              const reservation = finalizedResponse.fulfillment
                .value as HomesReservation;
              yield putResolve(
                setVacationRentalsConfirmationDetails(reservation)
              );
              yield putResolve(
                setPollVacationRentalsConfirmationDetailsCallStateSuccess()
              );

              let encryptedProperties = [
                ...completeBuyVacationRentalProperties.encryptedProperties,
              ]

              if(finalizedResponse.fulfillment.value.trackingProperties?.encryptedProperties?.length > 0) {
                encryptedProperties = [...encryptedProperties, ...finalizedResponse.fulfillment.value.trackingProperties?.encryptedProperties]
              }

              trackEvent({
                eventName: COMPLETE_BUY_VR,
                properties: {
                  ...completeBuyVacationRentalProperties.properties,
                  ...finalizedResponse.fulfillment.value.trackingProperties
                    .properties,
                  success: true,
                  reservation_id: reservation.orderId,
                  vr_type: reservation.listingCollection.toLowerCase(),
                },
                encryptedProperties: encryptedProperties,
              });
              return;
            default:
              pollFailed = true;
              yield putResolve(
                setPollVacationRentalsConfirmationDetailsCallStateFailure([])
              );
              throw new Error("Poll Finalized Failed");
          }
      }

      // if we want to give up on polling we should instead direct the user to my trips
      if (index >= delayTimes.length) {
        window.location.pathname = "/trips";
      }

      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    // const completeBuyPremierCollectionProperties: ITrackingProperties<CompleteBuyHotelProperties> =
    //   yield select(getCompleteBuyPremierCollectionProperties);
    trackEvent({
      eventName: COMPLETE_BUY_VR,
      properties: {
        failure_reason: "Poll Confirmation Details Network Call Failed",
      },
      encryptedProperties: [
        // ...completeBuyPremierCollectionProperties.encryptedProperties,
      ],
    });
    yield putResolve(
      setPollVacationRentalsConfirmationDetailsCallStateFailure([])
    );
  }
}
