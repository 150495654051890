import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../reducers/types";
import { OccupancySelection } from "./component";
import {
  getAdultsCount,
  getChildren,
  getPetsCount,
} from "../../../../../search/reducer/selectors";
import { setOccupancyCounts } from "../../../../../search/actions/actions";
import { getVacationRentalShopSelectedListing } from "../../../../../shop/reducer/selectors";

const mapDispatchToProps = {
  setOccupancyCounts: setOccupancyCounts,
};
const mapStateToProps = (state: IStoreState) => ({
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  petsCount: getPetsCount(state),
  selectedHome: getVacationRentalShopSelectedListing(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type OccupancySelectionConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedOccupancySelection = connector(OccupancySelection);
