import { connect, ConnectedProps } from "react-redux";
import {
  updateUserPassenger,
  setUserSelectedPassengerIds,
  deleteUserPassenger,
  fetchUserPassengers,
} from "../../actions/actions";
import {
  getUserPassengerCallState,
  getUserPassengers,
  getUserSelectedTravelerId,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { VacationRentalBookPassengerSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    travelers: getUserPassengers(state),
    selectedTravelerId: getUserSelectedTravelerId(state),
    userPassengerCallState: getUserPassengerCallState(state),
  };
};

const mapDispatchToProps = {
  updateUserPassenger,
  setUserSelectedPassengerIds,
  deleteUserPassenger,
  fetchUserPassengers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type VacationRentalBookPassengerSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedVacationRentalBookPassengerSelection = connector(
  VacationRentalBookPassengerSelection
);
