import axios from "axios";
import { config } from "../../config";
import { VacationRentalCalendarRequest, VacationRentalCalendarResponse } from "redmond";
import { vacationRentalCalendarApiPrefix } from "../paths";

export const fetchVacationRentalCalendar = (
    body: VacationRentalCalendarRequest
  ): Promise<VacationRentalCalendarResponse> =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await axios.put(vacationRentalCalendarApiPrefix, body, {
          baseURL: config.baseURL,
        });
        resolve(res.data);
      } catch (e) {
        reject(e);
      }
    });