import { IResult, GuestsSelection } from "redmond";

import * as actionTypes from "./constants";

export interface ISetLocation {
  type: actionTypes.SET_LOCATION;
  location: IResult | null;
}

export const setLocation = (location: IResult | null): ISetLocation => ({
  type: actionTypes.SET_LOCATION,
  location,
});

export interface ISetFromDate {
  type: actionTypes.SET_FROM_DATE;
  date: Date | null;
}

export const setFromDate = (date: Date | null): ISetFromDate => ({
  type: actionTypes.SET_FROM_DATE,
  date,
});

export interface ISetUntilDate {
  type: actionTypes.SET_UNTIL_DATE;
  date: Date | null;
}

export const setUntilDate = (date: Date | null): ISetUntilDate => ({
  type: actionTypes.SET_UNTIL_DATE,
  date,
});

export interface ISetOccupancyCounts {
  type: actionTypes.SET_OCCUPANCY_COUNTS;
  counts: Omit<GuestsSelection, "rooms">;
}

export const setOccupancyCounts = (
  counts: Omit<GuestsSelection, "rooms">
): ISetOccupancyCounts => ({
  type: actionTypes.SET_OCCUPANCY_COUNTS,
  counts,
});

export interface ISetVacationRentalsLocation {
  type: actionTypes.SET_VACATION_RENTALS_LOCATION;
  vacationRentalsLocation: IResult | null;
}

export const setVacationRentalsLocation = (
  vacationRentalsLocation: IResult | null
): ISetVacationRentalsLocation => ({
  type: actionTypes.SET_VACATION_RENTALS_LOCATION,
  vacationRentalsLocation,
});

export type VacationRentalSearchActions =
  | ISetLocation
  | ISetFromDate
  | ISetUntilDate
  | ISetOccupancyCounts
  | ISetVacationRentalsLocation;
