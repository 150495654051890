import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { fetchVacationRentalShop } from "../../actions/actions";

import { withRouter } from "react-router";
import { OccupancySelector } from "./component";
import { setOccupancyCounts } from "../../../search/actions/actions";
import {
  getAdultsCount,
  getChildren,
  getPetsCount,
} from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    adultsCount: getAdultsCount(state),
    childrenCount: getChildren(state),
    petsCount: getPetsCount(state),
  };
};

const mapDispatchToProps = {
  fetchVacationRentalShop,
  setOccupancyCounts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type OccupancySelectorConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedOccupancySelector = withRouter(
  connector(OccupancySelector)
);
